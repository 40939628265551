import { useAtom } from "jotai";
import {
  useClickAnchor,
  useFixedPosition,
  useGnbConfig,
} from "../../../businesslogics/_layout/gnb/v2";
import { GnbMyPageLogo } from "../atoms/GnbMyPageLogo";
import { LineBanner } from "../molecules/LineBanner/LineBanner";
import { GnbMyPage } from "../molecules/GnbMyPage.jsx";
import { LoginButton } from "../molecules/LoginButton";
import { useIsLoggedInV2 } from "../../../hooks/useSetAmplitudeUserId";
import * as S from "./Gnb.mobile2.style";
import { isMyPageOpenAtom } from "../../../stores/mobileMyPageTabOpen";
import { mobileGnbModelV3, sccAnchor } from "../models/gnbV3.models";
import { GnbSpartaLogoM } from "../../atoms/_layout/gnb/Gnb.style.svg.jsx";

export const GnbMobileV3 = () => {
  const [isMyPageOpen] = useAtom(isMyPageOpenAtom);
  const isFixed = useFixedPosition();
  const isLogin = useIsLoggedInV2();
  const onClickAnchor = useClickAnchor();
  const gnbConfig = useGnbConfig(mobileGnbModelV3);

  return (
    <S.Wrapper isTabOpened={isMyPageOpen} fixed={isFixed}>
      <S.TopMenuBar>
        <S.LeftBtnWrapper>
          {
            // gnbConfig?.left ? (
            //   <S.BtnWrapper onClick={() => gnbConfig.left.onClick()}>
            //     {gnbConfig.left.text}
            //   </S.BtnWrapper>
            // ) : (
            <S.LogoWrapper onClick={() => onClickAnchor(sccAnchor)}>
              <GnbSpartaLogoM />
            </S.LogoWrapper>
          }
        </S.LeftBtnWrapper>

        <S.GnbCenterContainer>
          {gnbConfig?.pageName && <S.PageName>{gnbConfig.pageName}</S.PageName>}
        </S.GnbCenterContainer>

        <S.GnbRightContainer>
          {isLogin ? (
            // gnbConfig?.right ? (
            //   <S.RightBtnWrapper onClick={() => gnbConfig.right.onClick()}>
            //     {gnbConfig.right.text}
            //   </S.RightBtnWrapper>
            // ) : (
            <GnbMyPageLogo />
          ) : (
            // )
            <LoginButton />
          )}
        </S.GnbRightContainer>
      </S.TopMenuBar>
      <LineBanner />
      <GnbMyPage /> {/* right SNB */}
    </S.Wrapper>
  );
};
