import * as S from "./Certificates.style";
import { DownloadIcon, Receipt } from "../Icons/Icons";
import { parsePeriod } from "./Certificates";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { EnrollmentCertificate } from "../CertificateTemplates/EnrollmentTemplate";
import { CompletionCertificateTemplate } from "../CertificateTemplates/CompletionTemplate";
import { isReceiptDownloadable } from "./Certificates";
import { useReceipt } from "../../../../queries/mypage";
import { sendLogCertificate } from "../../../../queries/mypage";
import { AttendanceTemplate } from "../CertificateTemplates/AttendanceTemplate";
import { getCertificateCourseName } from "./businessLogic";

const EnrollmentCertificateContent = ({ loading, enrollment }) => (
  <S.CertifiCardDownload
    isActive={enrollment.canIssueEnrollmentCertificate}
    onClick={() => sendLogCertificate("수강증", enrollment.systemType)}
  >
    {loading ? (
      <S.MobileSolvingImg src={"/v2/assets/icons/loading_spinner.png"} />
    ) : (
      <S.IconWrapper>
        <DownloadIcon />
      </S.IconWrapper>
    )}
    <S.CertifiCardDownloadTitle>수강증</S.CertifiCardDownloadTitle>
  </S.CertifiCardDownload>
);
const CompletionCertificateContent = ({ loading, enrollment }) => (
  <S.CertifiCardDownload
    isActive={enrollment.canIssueCompletionCertificate}
    onClick={() => sendLogCertificate("수료증", enrollment.systemType)}
  >
    {loading ? (
      <S.MobileSolvingImg src={"/v2/assets/icons/loading_spinner.png"} />
    ) : (
      <S.IconWrapper>
        <DownloadIcon />
      </S.IconWrapper>
    )}
    <S.CertifiCardDownloadTitle>수료증</S.CertifiCardDownloadTitle>
  </S.CertifiCardDownload>
);

const MobileAttendanceCertificateContent = ({ loading, enrollment }) => {
  return (
    <S.CertifiCardDownload isActive={enrollment?.canIssueAttendanceCertificate}>
      {loading ? (
        <S.MobileSolvingImg src={"/v2/assets/icons/loading_spinner.png"} />
      ) : (
        <S.IconWrapper>
          <DownloadIcon />
        </S.IconWrapper>
      )}
      <S.CertifiCardDownloadTitle>출석증</S.CertifiCardDownloadTitle>
    </S.CertifiCardDownload>
  );
};

const ReceiptCertificateContent = ({ loading, enrollment }) => {
  const { receiptInfo, handleReceiptClick } = useReceipt(enrollment);

  return (
    <S.CertifiCardDownload
      onClick={handleReceiptClick}
      isActive={isReceiptDownloadable(receiptInfo)}
    >
      <S.IconWrapper>
        <Receipt />
      </S.IconWrapper>
      <S.CertifiCardReceiptTitle>영수증 발급</S.CertifiCardReceiptTitle>
    </S.CertifiCardDownload>
  );
};

const MobileAttendanceCertificate = ({ enrollment }) => {
  if (!enrollment?.isKdc) return null;

  if (!enrollment?.canIssueAttendanceCertificate) {
    return <MobileAttendanceCertificateContent isActive={false} />;
  }

  if (enrollment?.systemType === "NEW") {
    return (
      <PDFDownloadLink
        document={
          <AttendanceTemplate
            enrollmentData={{
              name: enrollment.name,
              courseName: enrollment.courseName,
              phone: enrollment.phone,
              coursePeriod: parsePeriod(
                enrollment.startDate,
                enrollment.endDate
              ),
              isKdc: enrollment.isKdc,
              progress: enrollment?.attendanceInfo?.progress ?? 100,
            }}
            attendanceData={enrollment?.attendanceInfo?.attendanceDetail}
          />
        }
        fileName={`출석증_${enrollment.courseName}.pdf`}
      >
        {({ loading }) => (
          <MobileAttendanceCertificateContent
            loading={loading}
            enrollment={enrollment}
          />
        )}
      </PDFDownloadLink>
    );
  }

  return (
    <MobileAttendanceCertificateContent
      loading={false}
      isActive={enrollment?.canIssueAttendanceCertificate}
      enrollment={enrollment}
      onClick={() => {
        alert(
          "2025년 2월 24일 이전 구매한 과정은 마이페이지에서 발급이 불가합니다. 우측 하단 문의하기 버튼을 통해 문의해주세요."
        );
      }}
    />
  );
};

export const CertificateCardMobile = ({ enrollment }) => {
  return (
    <S.CertifiCard>
      {enrollment.isCompleted ? (
        <S.CourseCompleted>수료</S.CourseCompleted>
      ) : (
        <S.CourseEnded>미수료</S.CourseEnded>
      )}
      <S.CertifiCardTitleContainer>
        <S.CertifiCardTitle>{`${enrollment.courseName}`}</S.CertifiCardTitle>
        <S.CertifiCardSubTitle>
          {parsePeriod(enrollment.startDate, enrollment.endDate)}
        </S.CertifiCardSubTitle>
        <S.CertifiCardDownloadContainer>
          {/* 영수증 */}
          <ReceiptCertificateContent enrollment={enrollment} />
          {/* 수강증 */}
          {enrollment.canIssueEnrollmentCertificate ? (
            <PDFDownloadLink
              document={
                <EnrollmentCertificate
                  enrollmentData={{
                    name: enrollment.name,
                    courseName: getCertificateCourseName(enrollment),
                    coursePeriod: parsePeriod(
                      enrollment.startDate,
                      enrollment.endDate
                    ),
                    isKdc: enrollment.isKdc,
                  }}
                />
              }
              fileName={`수강증_${enrollment.courseName}.pdf`}
            >
              {({ loading }) => (
                <EnrollmentCertificateContent
                  loading={loading}
                  enrollment={enrollment}
                />
              )}
            </PDFDownloadLink>
          ) : (
            <EnrollmentCertificateContent
              loading={false}
              enrollment={enrollment}
            />
          )}
          {/* 수료증 */}
          {enrollment.canIssueCompletionCertificate ? (
            <PDFDownloadLink
              document={
                <CompletionCertificateTemplate
                  enrollmentData={{
                    name: enrollment.name,
                    courseName: getCertificateCourseName(enrollment),
                    coursePeriod: parsePeriod(
                      enrollment.startDate,
                      enrollment.endDate
                    ),
                    isKdc: enrollment.isKdc,
                  }}
                />
              }
              fileName={`수료증_${enrollment.courseName}.pdf`}
            >
              {({ loading }) => (
                <CompletionCertificateContent
                  loading={loading}
                  enrollment={enrollment}
                />
              )}
            </PDFDownloadLink>
          ) : (
            <CompletionCertificateContent
              loading={false}
              enrollment={enrollment}
            />
          )}
          {/* 출석증 */}
          <MobileAttendanceCertificate enrollment={enrollment} />
        </S.CertifiCardDownloadContainer>
      </S.CertifiCardTitleContainer>
    </S.CertifiCard>
  );
};
