import styled from "styled-components";
import { breakpoints, Device } from "../../../../../_styles/device";

export const MyCouponWrapper = styled.div`
  width: 100%;
  font-size: 16px;
  padding-top: 12px;
  min-height: 520px;

  ${Device.Desktop} {
    padding-top: 0;
    margin: 0 auto 25px auto;
    &.wide {
      border: none;
      box-shadow: none;
    }
  }

  /* @media (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.miniDesktop}px) {
    margin-top: 90px;
  } */
`;

export const Title = styled.h3`
  margin-bottom: 20px;
  font-family: Pretendard;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  color: #141617;
  display: none;
  ${Device.Desktop} {
    display: block;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  letter-spacing: -0.06em;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  position: relative;
  max-height: none;
  margin-bottom: 12px;

  ${Device.Desktop} {
    margin-bottom: 80px;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 20px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  ${Device.Desktop} {
    justify-content: flex-start;
  }
`;

export const CouponCodeInput = styled.input`
  width: calc(100% - 32px - 76px);
  height: 44px;
  margin: 0 12px 0 0;
  border-radius: 8px;
  border: 1px solid #d7e0e6;
  padding: 12px 16px;
  font-size: 14px;

  //focus
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #b4bfc6;
  }

  ${Device.Desktop} {
    padding: 14px 16px;
    width: 384px;
    height: 48px;
  }
`;

export const CouponRegisterButton = styled.input`
  width: 76px;
  height: 44px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #eef3f6;
  color: #141617;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  font-family: Pretendard;
  cursor: pointer;

  ${Device.Desktop} {
    width: 124px;
    height: 48px;
    font-size: 14px;
  }
`;

export const SubTitle = styled.h3`
  margin-bottom: 4px;
  font-family: Pretendard;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  color: #141617;
  b {
    font-family: Pretendard;
    color: #e8344e;
    font-size: 16px;
  }
`;

export const NoCouponGuide = styled.div`
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin: 96px 0;
  padding: 100px 0;
  ${Device.Desktop} {
    margin-top: 32px;
  }
`;

export const CouponsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #eef3f6;
  justify-content: center;

  ${Device.Desktop} {
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
  }
`;

export const CouponSectionWrapper = styled.div`
  width: 100%;
  background-color: #eef3f6;
  padding: 24px 16px;
  min-height: 520px;
  ${Device.Desktop} {
    min-height: 0;
    background-color: #fff;
    padding: 0;
  }
`;

export const NoCouponGuideImg = styled.img`
  width: 160px;
  margin-bottom: 16px;
`;
export const NoCouponGuideTitle = styled.p`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #3a3e41;
  margin-bottom: 8px;
`;

export const NoCouponGuideDesc = styled.p`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #81898f;
  text-align: center;
  white-space: nowrap;
  b {
    font-family: "Pretendard";
    color: #e8344e;
  }
`;
