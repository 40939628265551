import { useDevice } from "../../../../pages/NBCampAttendPage";
import { PIRATES } from "../../../../../_consts";
import styled from "styled-components";

export const CategoryLogoSVG = ({ category }) => {
  switch (category) {
    case "scc":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1894_59916)">
            <g clipPath="url(#clip1_1894_59916)">
              <path
                d="M11.901 0.202515L1.81199 2.41438L0 8.35045L4.09898 13.0703L7.20304 12.3892L8.10249 15.7975L12.1973 11.4113L14.188 10.8608L16 4.92241L11.901 0.202515Z"
                fill="#E8344E"
              />
              <path
                d="M11.6776 5.06329V5.61943H12.2083V6.17557H12.739V6.73171H13.2692V7.28785H12.739V7.84399H12.2083V8.40013H11.6781V8.95627H10.6172V8.40013H11.1474V7.84399H11.6776V7.28785H12.2083V6.73171H11.6781V6.1751H11.1479V5.61896H10.6177V5.06329H11.6776Z"
                fill="white"
              />
              <path
                d="M9.93918 5.16156H9.01399V5.69843H8.49127V6.24422H7.99662V6.78062H7.47389V7.32688H6.97878V7.86328H6.45605V8.40955H5.96094V8.95534H6.88659V8.40955H7.38124V7.87268H7.90396V7.32688H8.39861V6.79049H8.9218V6.24422H9.41645V5.70783H9.93918V5.16156Z"
                fill="white"
              />
              <path
                d="M4.22439 9.00471V8.44857H3.69371V7.89243H3.16303V7.33629H2.63281V6.78015H3.16303V6.22401H3.69371V5.66787H4.22392V5.11267H5.28482V5.66881H4.7546V6.22495H4.22439V6.78109H3.69371V7.33723H4.22392V7.89337H4.75413V8.44951H5.28435V9.00565H4.22439V9.00471Z"
                fill="white"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_1894_59916">
              <rect width="16" height="16" fill="white" />
            </clipPath>
            <clipPath id="clip1_1894_59916">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "catalog":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#E8344E" />
          <path
            d="M12.2041 6V6.62738H12.8027V7.25476H13.4014V7.88215H13.9995V8.50953H13.4014V9.13691H12.8027V9.76429H12.2046V10.3917H11.0078V9.76429H11.6059V9.13691H12.2041V8.50953H12.8027V7.88215H12.2046V7.25423H11.6065V6.62685H11.0083V6H12.2041Z"
            fill="white"
          />
          <path
            d="M10.2418 6.11078H9.19807V6.71642H8.60838V7.33213H8.05036V7.93724H7.46068V8.55349H6.90214V9.15859H6.31245V9.77484H5.75391V10.3906H6.79813V9.77484H7.35615V9.1692H7.94584V8.55349H8.50385V7.94838H9.09407V7.33213H9.65208V6.72702H10.2418V6.11078Z"
            fill="white"
          />
          <path
            d="M3.79546 10.4463V9.8189H3.1968V9.19151H2.59814V8.56413H2V7.93675H2.59814V7.30937H3.1968V6.68199H3.79493V6.05566H4.99173V6.68305H4.3936V7.31043H3.79546V7.93781H3.1968V8.56519H3.79493V9.19257H4.39307V9.81996H4.9912V10.4473H3.79546V10.4463Z"
            fill="white"
          />
        </svg>
      );
    case "nb":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#419400" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.04947 5.81899L3.84355 9.27844L7.00224 10.4262L7.00224 9.42584L4.42754 7.59591L4.60245 7.29295L2.04947 5.81899ZM2.05209 5.81908L5.94511 5.64306L8.09297 7.94954L8.00574 8.92567L4.77999 6.99009L4.60508 7.29304L2.05209 5.81908Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9505 5.8185L12.1565 9.27795L8.99776 10.4257L8.99776 9.42535L11.5725 7.59542L11.3975 7.29246L13.9505 5.8185ZM13.9479 5.81895L10.0549 5.64294L7.90703 7.94941L7.99426 8.92555L11.22 6.98996L11.3949 7.29292L13.9479 5.81895Z"
            fill="white"
          />
          <path d="M7.5 10.0001H8.5L9 14.0001H7L7.5 10.0001Z" fill="white" />
        </svg>
      );
    case "chang":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1839_22874)">
            <rect width="16" height="16" rx="8" fill="#0F16AE" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.08023 13.4908C6.12032 13.5915 6.36689 13.6101 6.9999 14.3305C8.03436 15.5079 6.9999 26 6.9999 26H7.98223H8.01757H8.9999C8.9999 26 7.96544 15.5079 8.9999 14.3305C9.63291 13.6101 9.87948 13.5915 9.91957 13.4908C9.94499 13.4269 9.88736 13.33 9.79257 13H8.01757H7.98223H6.20723C6.11244 13.33 6.05481 13.4269 6.08023 13.4908Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 3L5 7.69301L6.32 12H7.5V7.21346H8V7.21352H8.5V12H9.68L11 7.69306L8 3.00005V3Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1839_22874">
              <rect width="16" height="16" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "hanghae":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#F50000" />
          <path
            d="M6.53178 4.3145C5.51711 3.62038 3.95173 4.11406 3.03513 5.41323C2.11854 6.7124 2.20152 8.32709 3.21996 9.02122C4.23463 9.71535 5.80001 9.22166 6.71661 7.92249C7.62943 6.62332 7.54645 5.00863 6.53178 4.3145ZM4.02717 7.87424C3.50664 7.51789 3.46514 6.69384 3.93287 6.0257C4.4006 5.36127 5.20026 5.10886 5.7208 5.4652C6.24134 5.82154 6.28283 6.64559 5.8151 7.31374C5.34737 7.97446 4.54771 8.22687 4.02717 7.87424Z"
            fill="white"
          />
          <path
            d="M7.05967 7.30261L4.58901 12.1392L3.6875 11.2335L5.4113 7.96705L7.05967 7.30261Z"
            fill="white"
          />
          <path
            d="M12.2076 4.3145C11.1929 3.62038 9.62751 4.11406 8.71092 5.41323C7.79432 6.7124 7.8773 8.32709 8.89574 9.02122C9.91419 9.71535 11.4758 9.22166 12.3924 7.92249C13.3052 6.62332 13.226 5.00863 12.2076 4.3145ZM9.70295 7.87424C9.18242 7.51789 9.14093 6.69384 9.60865 6.0257C10.0764 5.35755 10.8798 5.10886 11.4004 5.46149C11.9209 5.81783 11.9624 6.64188 11.4947 7.31002C11.0269 7.97446 10.2235 8.22687 9.70295 7.87424Z"
            fill="white"
          />
          <path
            d="M12.6607 7.48822L10.1938 12.8L9.27344 11.8943L11.0123 7.96706L12.6607 7.48822Z"
            fill="white"
          />
        </svg>
      );
    case "swcamp":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <rect x="0.789062" width="16" height="16" rx="8" fill="#141617" />
          <path
            d="M4.78906 5.55196V7.21696L10.6907 10.448H6.52V9.39535H4.78906V10.448V11.058V12H12.4782V10.448H11.2913L11.2866 8.89844L6.52 6.39976V5.55196H10.7449V6.60465H12.4782V5.55196V4.94201V4H4.78906V5.55196Z"
            fill="white"
          />
        </svg>
      );
    case "ddingdong":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#FF7005" />
          <path
            d="M11.262 9.0794V7.7199C11.262 6.15908 10.1624 4.85452 8.69945 4.5377V3.99257C8.69945 3.60586 8.38728 3.2937 8.00057 3.2937C7.61386 3.2937 7.3017 3.60586 7.3017 3.99257V4.5377C5.83873 4.85452 4.73916 6.15908 4.73916 7.7199V9.0794L4.13813 9.68043C3.84461 9.97396 4.04961 10.4772 4.46427 10.4772H11.5322C11.9469 10.4772 12.1565 9.97396 11.863 9.68043L11.262 9.0794Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.50012 11.4771C6.66074 12.1819 7.27131 12.7064 8 12.7064C8.72869 12.7064 9.33927 12.1819 9.49988 11.4771H6.50012Z"
            fill="white"
          />
        </svg>
      );
    default:
      return <></>;
  }
};

export const MyPageLogoSVG = ({ isMouseOnLogo, theme }) => {
  const color = isMouseOnLogo
    ? "#e8344e"
    : theme === PIRATES
    ? "white"
    : "#141617";
  const device = useDevice();
  const isMobile = device === "mobile";
  return (
    <svg
      width={isMobile ? "20" : "24"}
      height={isMobile ? "20" : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="8" r="4" stroke={color} strokeWidth="2" />
      <path
        d="M4.20703 19.998C5.76317 17.3079 8.67172 15.498 12.003 15.498C15.3343 15.498 18.2428 17.3079 19.799 19.998"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const HamburgerSVG = ({ theme }) => {
  const color = theme === PIRATES ? "white" : "#141617";
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33203 5H16.6654"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <path
        d="M3.33203 10H16.6654"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <path
        d="M3.33203 15H16.6654"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const RightArrowSVG = ({ theme, isActive }) => {
  const getColor = () => {
    switch (theme) {
      case PIRATES:
        return isActive ? "#FF6C7A" : "#9DA7AE";
      default:
        return isActive ? "#e8344e" : "#81898f";
    }
  };
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.13373 11.4697C5.84084 11.7626 5.84084 12.2374 6.13373 12.5303C6.42663 12.8232 6.9015 12.8232 7.19439 12.5303L6.13373 11.4697ZM10.6641 8L11.1944 8.53033C11.4873 8.23744 11.4873 7.76256 11.1944 7.46967L10.6641 8ZM7.19439 3.46967C6.9015 3.17678 6.42663 3.17678 6.13373 3.46967C5.84084 3.76256 5.84084 4.23744 6.13373 4.53033L7.19439 3.46967ZM7.19439 12.5303L11.1944 8.53033L10.1337 7.46967L6.13373 11.4697L7.19439 12.5303ZM11.1944 7.46967L7.19439 3.46967L6.13373 4.53033L10.1337 8.53033L11.1944 7.46967Z"
        fill={getColor()}
      />
    </svg>
  );
};

export const MyPageBalloonSVG = () => (
  <svg
    width="306"
    height="604"
    viewBox="0 0 306 604"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2011_73786)">
      <mask id="path-1-inside-1_2011_73786" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M273.25 16.9971H282C288.627 16.9971 294 22.3697 294 28.9971V575.997C294 582.625 288.627 587.997 282 587.997H24C17.3726 587.997 12 582.625 12 575.997V28.9971C12 22.3697 17.3726 16.9971 24 16.9971H258.75L264.698 8.67002C265.336 7.77666 266.664 7.77666 267.302 8.67002L273.25 16.9971Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M273.25 16.9971H282C288.627 16.9971 294 22.3697 294 28.9971V575.997C294 582.625 288.627 587.997 282 587.997H24C17.3726 587.997 12 582.625 12 575.997V28.9971C12 22.3697 17.3726 16.9971 24 16.9971H258.75L264.698 8.67002C265.336 7.77666 266.664 7.77666 267.302 8.67002L273.25 16.9971Z"
        fill="white"
      />
      <path
        d="M273.25 16.9971L272.436 17.5783L272.735 17.9971H273.25V16.9971ZM258.75 16.9971V17.9971H259.265L259.564 17.5783L258.75 16.9971ZM264.698 8.67002L265.512 9.25126V9.25125L264.698 8.67002ZM267.302 8.67002L266.488 9.25125V9.25126L267.302 8.67002ZM273.25 17.9971H282V15.9971H273.25V17.9971ZM282 17.9971C288.075 17.9971 293 22.9219 293 28.9971H295C295 21.8174 289.18 15.9971 282 15.9971V17.9971ZM293 28.9971V575.997H295V28.9971H293ZM293 575.997C293 582.072 288.075 586.997 282 586.997V588.997C289.18 588.997 295 583.177 295 575.997H293ZM282 586.997H24V588.997H282V586.997ZM24 586.997C17.9249 586.997 13 582.072 13 575.997H11C11 583.177 16.8203 588.997 24 588.997V586.997ZM13 575.997V28.9971H11V575.997H13ZM13 28.9971C13 22.9219 17.9249 17.9971 24 17.9971V15.9971C16.8203 15.9971 11 21.8174 11 28.9971H13ZM24 17.9971H258.75V15.9971H24V17.9971ZM259.564 17.5783L265.512 9.25126L263.884 8.08878L257.936 16.4158L259.564 17.5783ZM265.512 9.25125C265.751 8.91625 266.249 8.91625 266.488 9.25125L268.116 8.08878C267.079 6.63707 264.921 6.63707 263.884 8.08878L265.512 9.25125ZM266.488 9.25126L272.436 17.5783L274.064 16.4158L268.116 8.08878L266.488 9.25126Z"
        fill="#E4EBF0"
        mask="url(#path-1-inside-1_2011_73786)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2011_73786"
        x="0"
        y="0"
        width="306"
        height="603.997"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2011_73786"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2011_73786"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const CloseSvg = ({ theme }) => {
  const color = theme === PIRATES ? "#fff" : "#141617";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.16797 4.16699L15.8346 15.8337"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
      />
      <path
        d="M15.832 4.16699L4.16536 15.8337"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const GnbSpartaLogo = ({ showMainLogo = true }) => {
  return (
    <LogoWrapper>
      <svg
        width="84"
        height="30"
        viewBox="0 0 84 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M70.4005 6.3369H68.5198V7.25246H70.4005V6.3369Z"
          fill="#E8344E"
        />
        <path
          d="M70.4005 8.28493H68.5198V13.3153H70.4005V8.28493Z"
          fill="#E8344E"
        />
        <path
          d="M58.3751 15.3758H56.4944V22.441H58.3751V15.3758Z"
          fill="#E8344E"
        />
        <path
          d="M53.3757 12.3989V9.45458H55.4136V8.53902H52.4805L51.4543 9.44319V12.2543V12.3989L52.4805 13.3149H55.4136V12.3993H53.3745V12.3989H53.3757Z"
          fill="#E8344E"
        />
        <path
          d="M75.4258 8.38443H73.5821H71.7012V9.29958V13.3148H73.5821V9.29958H74.5715V13.3158H76.4522V9.28819L75.4258 8.38402"
          fill="#E8344E"
        />
        <path
          d="M70.4385 21.5299V18.3096H70.4398L70.4385 18.3085V18.3049H70.4347L69.4689 17.4319V17.4307H69.4671L69.4655 17.429V17.4307H67.3816V15.3755H65.5007V22.4407H66.5178H69.4689L70.4381 21.5295L70.4385 21.5299ZM68.5574 18.3467V21.5261H67.3816V18.3467H68.5574Z"
          fill="#E8344E"
        />
        <path
          d="M81.4798 15.3577L82.4696 14.4439V8.38077H78.5598L77.5352 9.28369H77.5334V13.4209H80.5883V14.4417H78.528V15.3573H81.479M79.4147 12.5056V9.29633H80.5889V12.5056H79.4147Z"
          fill="#E8344E"
        />
        <path
          d="M57.456 8.53902L56.4298 9.44319V12.3984L57.456 13.3145H60.3744L61.4007 12.3984V9.44319L60.3744 8.53902H57.456ZM59.4788 12.3989V12.3997H58.3511V9.45458H59.4788V12.3989Z"
          fill="#E8344E"
        />
        <path
          d="M65.5036 6.35612V8.53906H63.4694L62.4436 9.44282H62.4432V12.3998H62.444L63.4661 13.312V13.3145H63.469L63.4698 13.3153V13.3145H65.504H67.4254V6.35612H65.504H65.5036ZM65.5036 12.3993H64.365V9.45421H65.5036V12.3993Z"
          fill="#E8344E"
        />
        <path
          d="M53.335 21.5414V18.3467H55.4341V17.4311H52.4805L51.4543 18.3353V21.5414H51.4547L52.4805 22.4574H55.4341V21.5418H53.335"
          fill="#E8344E"
        />
        <path
          d="M64.3985 17.4311H62.5178V21.5367H61.4039V17.4311H59.5232V21.5345H59.5203L59.5232 21.5371V21.5404H59.5265L60.5445 22.4489V22.4509H63.4955V22.4413H64.3985V17.4311Z"
          fill="#E8344E"
        />
        <path
          d="M21.9723 12.4537H21.2382V13.1922H20.5038V14.6696H22.7067V13.1922H21.9723V12.4537Z"
          fill="#E8344E"
        />
        <path
          d="M16.9722 12.4537H14.8418V13.9192H16.9722V12.4537Z"
          fill="#E8344E"
        />
        <path
          d="M28.9115 13.1928V12.4537H26.7826V13.9313H28.9164L28.9115 13.1928Z"
          fill="#E8344E"
        />
        <path
          d="M38.9673 12.4537H38.2329V13.1922H37.4988V14.6696H39.7017V13.1922H38.9673V12.4537Z"
          fill="#E8344E"
        />
        <path
          d="M40.7096 2.30769L2.81196 9.8003L0 19.1801L6.32557 26.4646L31.5071 21.4861L32.8949 26.4566L39.5543 19.895L44.5551 18.9065L47.2673 9.85971L40.7096 2.30769ZM12.2121 13.1924H10.7742V12.4539H8.84347V13.9311H11.5679V14.6696H12.2117V16.147H11.5679V16.8855H8.04927V16.147H7.36649L7.36817 15.4083H8.8062L8.84347 16.147H10.7369V14.6696H8.04927V13.9311H7.40544V12.4539H8.04927V11.7153H11.5679V12.4539H12.2117V13.1924H12.2121ZM18.1137 13.9193H17.6676V14.6578H14.8419V16.8855H13.6798V12.4602H14.2031V11.7149H17.6676V12.4535H18.1137V13.9189V13.9193ZM23.9463 16.8855H22.7069V15.4083H20.504V16.8855H19.2646V13.1924H19.7696V12.4539H20.504V11.7153H22.7069V12.4539H23.4412V13.1924H23.9463V16.8855ZM30.38 16.8855H28.9113L28.9164 16.1415H28.2511V15.4955H27.6283V14.6702H26.7826V16.8855H25.5821V12.4602H26.1103V11.7149H29.646V12.4535H30.1513V13.9134H29.603V14.5537H28.9119V15.4079H29.646V16.1464H30.3804V16.8851L30.38 16.8855ZM35.6486 12.4539H34.1416V16.8855H32.7889V12.4539H31.2428V11.7153H35.6486V12.4539ZM40.9404 16.8855H39.701V15.4083H37.4981V16.8855H36.2587V13.1924H36.7637V12.4539H37.4981V11.7153H39.701V12.4539H40.4353V13.1924H40.9404V16.8855Z"
          fill="#E8344E"
        />
        <path
          d="M39.7626 23.4655V23.9046H40.1811V24.3427H40.5999V24.7817H41.0183V25.2208H40.5999V25.6602H40.1811V26.0993H39.7626V26.5383H38.9252V26.1002H39.3442V25.6612H39.7626V25.2217H40.1811V24.7827H39.7626V24.3427H39.3442V23.9046H38.9252V23.4655H39.7626Z"
          fill="#E8344E"
        />
        <path
          d="M38.0038 23.5043H37.2746V23.9278H36.8628V24.3592H36.4721V24.7832H36.0603V25.2142H35.6701V25.6379H35.2584V26.0695H34.868V26.5005H35.5973V26.0695H35.9873V25.6456H36.3994V25.2142H36.789V24.7906H37.2013V24.3592H37.5913V23.9353H38.0034V23.5043H38.0038Z"
          fill="#E8344E"
        />
        <path
          d="M30.3792 26.5387V26.0996H29.9603V25.6606H29.5417V25.2211H29.1233V24.7821H29.5417V24.3423H29.9603V23.904H30.3792V23.465H31.2164V23.904H30.7976V24.3423H30.3792V24.7811H29.9603V25.2202H30.3792V25.6596H30.7976V26.0987H31.2164V26.5377L30.3792 26.5387Z"
          fill="#E8344E"
        />
      </svg>
    </LogoWrapper>
  );
};

export const GnbSpartaLogoM = () => {
  return (
    <LogoWrapper>
      <svg
        width="41"
        height="21"
        viewBox="0 0 41 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group">
          <g id="Group 1000004824">
            <path
              id="Vector"
              d="M19.0426 8.79321H18.4063V9.43325H17.7699V10.7136H19.6791V9.43325H19.0426V8.79321Z"
              fill="#E8344E"
            />
            <path
              id="Vector_2"
              d="M14.7093 8.79321H12.8629V10.0632H14.7093V8.79321Z"
              fill="#E8344E"
            />
            <path
              id="Vector_3"
              d="M25.0566 9.43377V8.79321H23.2115V10.0738H25.0608L25.0566 9.43377Z"
              fill="#E8344E"
            />
            <path
              id="Vector_4"
              d="M33.7716 8.79321H33.1352V9.43325H32.4989V10.7136H34.4081V9.43325H33.7716V8.79321Z"
              fill="#E8344E"
            />
            <path
              id="Vector_5"
              d="M35.2817 0L2.43703 6.49359L0 14.6227L5.48216 20.936L27.3061 16.6212L28.5089 20.929L34.2804 15.2423L38.6144 14.3857L40.965 6.54508L35.2817 0ZM10.5838 9.43343H9.33763V8.79335H7.66434V10.0736H10.0255V10.7137H10.5835V11.994H10.0255V12.6341H6.97603V11.994H6.38429L6.38575 11.3538H7.63204L7.66434 11.994H9.30529V10.7137H6.97603V10.0736H6.41805V8.79335H6.97603V8.15323H10.0255V8.79335H10.5835V9.43343H10.5838ZM15.6986 10.0634H15.3119V10.7034H12.863V12.6341H11.8559V8.79884H12.3094V8.15287H15.3119V8.793H15.6986V10.0631V10.0634ZM20.7534 12.6341H19.6793V11.3538H17.7701V12.6341H16.696V9.43343H17.1336V8.79335H17.7701V8.15323H19.6793V8.79335H20.3157V9.43343H20.7534V12.6341ZM26.3293 12.6341H25.0564L25.0609 11.9893H24.4843V11.4294H23.9445V10.7142H23.2116V12.6341H22.1711V8.79884H22.6289V8.15287H25.6932V8.793H26.1311V10.0583H25.656V10.6132H25.057V11.3535H25.6932V11.9935H26.3297V12.6337L26.3293 12.6341ZM30.8955 8.79335H29.5894V12.6341H28.417V8.79335H27.0771V8.15323H30.8955V8.79335ZM35.4817 12.6341H34.4075V11.3538H32.4983V12.6341H31.4242V9.43343H31.8619V8.79335H32.4983V8.15323H34.4075V8.79335H35.044V9.43343H35.4817V12.6341Z"
              fill="#E8344E"
            />
            <path
              id="Vector_6"
              d="M34.4609 18.3368V18.7173H34.8235V19.097H35.1865V19.4775H35.5492V19.858H35.1865V20.2388H34.8235V20.6193H34.4609V20.9998H33.7351V20.6202H34.0983V20.2397H34.4609V19.8588H34.8235V19.4783H34.4609V19.097H34.0983V18.7173H33.7351V18.3368H34.4609Z"
              fill="#E8344E"
            />
            <path
              id="Vector_7"
              d="M32.9367 18.3704H32.3046V18.7374H31.9478V19.1113H31.6091V19.4787H31.2523V19.8522H30.9142V20.2195H30.5573V20.5935H30.219V20.967H30.851V20.5935H31.189V20.2261H31.5462V19.8522H31.8838V19.4852H32.2412V19.1113H32.5791V18.7439H32.9363V18.3704H32.9367Z"
              fill="#E8344E"
            />
            <path
              id="Vector_8"
              d="M26.3287 21.0002V20.6197H25.9657V20.2392H25.6029V19.8583H25.2402V19.4778H25.6029V19.0966H25.9657V18.7168H26.3287V18.3363H27.0543V18.7168H26.6913V19.0966H26.3287V19.477H25.9657V19.8575H26.3287V20.2383H26.6913V20.6189H27.0543V20.9994L26.3287 21.0002Z"
              fill="#E8344E"
            />
          </g>
        </g>
      </svg>
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4.25px;
`;
