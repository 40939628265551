import { useAtom } from "jotai";
import { useRef } from "react";
import {
  useClickAnchor,
  useOnOutsideClick,
} from "../../../businesslogics/_layout/gnb/v2";
import { useCouponCountText } from "../../../businesslogics/mypage/mypage.hooks";
import { useIsLoggedInV2 } from "../../../hooks/useSetAmplitudeUserId";
import { useUserMetaData } from "../../../queries/gnb";
import { isMyPageOpenAtom } from "../../../stores/mobileMyPageTabOpen";
import { GnbRowMobile } from "../atoms/GnbRow.mobile.jsx";
import {
  couponAnchor,
  logoutAnchor,
  mobileMyPageAnchors,
  pointAnchor,
} from "../models/gnbV3.models";
import * as S from "./GnbMyPage.style.js";
import { CouponIcon, PointIcon } from "./GnbMyPage.svg";
import {
  useUser,
  getUserId,
} from "../../../pages/LecturePageV3/useInitialize.js";

export const GnbMyPage = () => {
  const [show, setIsMyPageOpenAtom] = useAtom(isMyPageOpenAtom);
  const isLoggedIn = useIsLoggedInV2();
  const user = useUser();
  const userName = user?.name;
  const userEmail = user?.email;
  const userId = getUserId();
  const userMetaData = useUserMetaData(userId);

  const clickAnchor = useClickAnchor();

  const handlePointClick = () => {
    console.log("포인트 클릭 핸들러 실행");
    clickAnchor(pointAnchor);
  };

  const handleCouponClick = () => {
    console.log("쿠폰 클릭 핸들러 실행");
    clickAnchor(couponAnchor);
  };

  const myPageRef = useRef(null);
  useOnOutsideClick(myPageRef, () => setIsMyPageOpenAtom(false));
  const couponCount = useCouponCountText(userId);

  return (
    <S.ToolTipBox show={show} isShort={!isLoggedIn} ref={myPageRef}>
      <S.MyPageContainer>
        <S.GreetingContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              marginLeft: "30px",
            }}
          >
            <S.GreetingProfileIcon src={"/v2/assets/rtan/developer.png"} />
            <S.ProfileContainer>
              <S.UserName>{userName}</S.UserName>
              <S.EmailContainer>{userEmail}</S.EmailContainer>
            </S.ProfileContainer>
          </div>
        </S.GreetingContainer>
        <S.PointCouponContainer>
          <S.PointCouponWrapper onClick={handlePointClick}>
            <S.TitleWrapper>
              <PointIcon />
              <S.PointCouponTitle>{pointAnchor?.name}</S.PointCouponTitle>
            </S.TitleWrapper>
            <S.PointCouponContent>
              {!userMetaData.isLoading && userMetaData.data?.point}
            </S.PointCouponContent>
          </S.PointCouponWrapper>
          <S.ReversDivider />
          <S.PointCouponWrapper onClick={handleCouponClick}>
            <S.TitleWrapper>
              <CouponIcon />
              <S.PointCouponTitle>{couponAnchor?.name}</S.PointCouponTitle>
            </S.TitleWrapper>
            <S.PointCouponContent>{couponCount}</S.PointCouponContent>
          </S.PointCouponWrapper>
        </S.PointCouponContainer>
        <S.RowsContainer>
          <S.Divider />
          <S.Group>
            {mobileMyPageAnchors.map((anchor) => (
              <div key={`gnb-mypage-mobile-${anchor.key}`}>
                {(isLoggedIn || !anchor.needLogin) && (
                  <div key={`gnb-mypage-mobile-${anchor.key}`}>
                    <GnbRowMobile anchor={anchor} />
                  </div>
                )}
              </div>
            ))}
          </S.Group>
          <S.Divider />
          <S.Group>
            <GnbRowMobile anchor={logoutAnchor} />
          </S.Group>
        </S.RowsContainer>
      </S.MyPageContainer>
    </S.ToolTipBox>
  );
};
