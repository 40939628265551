import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { differenceInDays, format } from "date-fns";
import React from "react";
import PretendardBold from "./fonts/Pretendard-Bold.otf";
import Pretendard from "./fonts/Pretendard-Regular.otf";
import signatureImage from "./images/signature.png";
import logoImage from "./images/cert_logo.png";
import { formatPhoneNumber } from "@teamsparta/utils";

Font.register({
  family: "Pretendard",
  fonts: [
    {
      src: Pretendard,
      fontWeight: "normal",
    },
    {
      src: PretendardBold,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 60,
    marginBottom: 100,
    fontFamily: "Pretendard",
  },
  logo: {
    width: 60,
    height: 18,
  },
  title: {
    color: "#141617",
    fontSize: 16,
    marginTop: 24,
    fontStyle: "normal",
    fontWeight: 700,
  },
  table: {
    width: "100%",
    marginTop: 40,
    border: "0.5px solid #3A3E41",
    borderBottom: 0,
  },
  noMarginTable: {
    width: "100%",
    border: "0.5px solid #3A3E41",
    borderBottom: 0,
  },
  tableHeader: {
    display: "flex",
    padding: "8px 10px",
    width: "100%",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: "0.5px solid #C7D2D8",
    backgroundColor: "#F6F9FA",
  },
  tableHeaderText: {
    color: "#3A3E41",
    textAlign: "center",
    fontSize: 9,
    fontStyle: "normal",
    fontWeight: 700,
  },
  tableSubHeaderText: {
    color: "#3A3E41",
    textAlign: "center",
    fontSize: 9,
    fontStyle: "normal",
    fontWeight: 700,
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "0.5px solid #C7D2D8",
  },
  tableCol0: {
    width: "70px",
    borderRight: "0.5px solid #C7D2D8",
    padding: "8px 10px",
    backgroundColor: "#FFF",
    height: "136.5px", // 5줄 높이 (패딩 + 텍스트 높이)
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "0.5px solid #C7D2D8",
    flexDirection: "row",
  },
  tableCol1: {
    width: "70px",
    borderRight: "0.5px solid #C7D2D8",
    padding: "8px 10px",
    backgroundColor: "#FFF",
  },
  tableCol2: {
    width: "70%",
    padding: "8pt 10pt",
    backgroundColor: "#FFF",
  },
  tableRowLast: {
    flexDirection: "row",
    borderBottom: "0.5px solid #3A3E41",
  },
  cellText: {
    color: "#3A3E41",
    fontFamily: "Pretendard",
    fontSize: 9,
    fontStyle: "normal",
    fontWeight: 500,
  },
  contentsText: {
    textAlign: "center",
    fontFamily: "Pretendard",
    fontSize: 14,
    fontWeight: "normal",
  },
  signatureContainer: {
    textAlign: "center",
  },
  signatureName: {
    color: "#141617",
    textAlign: "center",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 600,
    fontFamily: "Pretendard",
  },
  signature: {
    position: "absolute",
    width: 38,
    height: 38,
    right: -7,
    top: -12,
  },
  footerText: {
    fontSize: 12,
    fontFamily: "Pretendard",
  },
  dateContainer: {
    marginTop: 48,
    textAlign: "center",
  },
  defaultText: {
    fontSize: 14,
    fontFamily: "Pretendard",
  },
  contentsContainer: {
    marginTop: 68,
    textAlign: "center",
  },
  footerContainer: {
    position: "absolute",
    bottom: 60, // 페이지 하단에서의 거리
    left: 60,
    right: 60,
    display: "flex",
    flexDirection: "column",
    gap: 40,
    alignItems: "center",
  },
  footerLabel: {
    color: "#3A3E41",
    fontFamily: "Pretendard",
    fontSize: 9,
    fontStyle: "normal",
    fontWeight: 700,
    width: 78,
  },
  footerInfoContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    width: "100%",
    alignItems: "flex-start",
  },
  footerSignatureContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    alignItems: "center",
  },
  footerValue: {
    color: "#3A3E41",
    fontFamily: "Pretendard",
    fontSize: 9,
    fontStyle: "normal",
    fontWeight: 500,
    width: "70%",
  },
  kdcFooterImage: {
    width: "184px",
    height: "24px",
    alignSelf: "center",
  },
  dateText: {
    color: "#81898F",
    textAlign: "center",
    fontFamily: "Pretendard",
    fontSize: 9,
    fontStyle: "normal",
    fontWeight: 500,
  },
  tableCol0Last: {
    borderBottom: "none",
  },
  attendanceTableHeader: {
    flexDirection: "row",
    borderBottom: "0.5px solid #C7D2D8",
    backgroundColor: "#F6F9FA",
  },
  attendanceHeaderFirstCell: {
    padding: "8px 10px",
    borderRight: "0.5px solid #C7D2D8",
    width: "60px",
    backgroundColor: "#FFFFFF",
  },
  attendanceHeaderCell: {
    padding: "8px 10px",
    borderRight: "0.5px solid #C7D2D8",
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  attendanceHeaderCellLast: {
    padding: "8px 10px",
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  attendanceRow: {
    flexDirection: "row",
    borderBottom: "0.5px solid #C7D2D8",
  },
  attendanceCellFirst: {
    padding: "8px 10px",
    borderRight: "0.5px solid #C7D2D8",
    width: "60px",
  },
  attendanceCell: {
    padding: "8px 10px",
    borderRight: "0.5px solid #C7D2D8",
    flex: 1,
  },
  attendanceCellLast: {
    padding: "8px 10px",
    flex: 1,
  },
  attendanceRowLast: {
    flexDirection: "row",
    borderBottom: "0.5px solid #3A3E41",
  },
});

const TableRow = ({ label, value, isLast = false }) => (
  <View style={isLast ? styles.tableRowLast : styles.tableRow}>
    <View style={styles.tableCol1}>
      <Text style={styles.cellText}>{label}</Text>
    </View>
    <View style={styles.tableCol2}>
      <Text style={styles.cellText}>{value}</Text>
    </View>
  </View>
);

const AttendanceTableHeader = () => (
  <View style={styles.attendanceTableHeader}>
    <View style={styles.attendanceHeaderFirstCell}>
      <Text style={styles.cellText}>기록 순번</Text>
    </View>
    <View style={styles.attendanceHeaderCell}>
      <Text style={styles.cellText}>출석 일시</Text>
    </View>
    <View style={styles.attendanceHeaderCell}>
      <Text style={styles.cellText}>학습 종료 일시</Text>
    </View>
    <View style={styles.attendanceHeaderCellLast}>
      <Text style={styles.cellText}>학습 인정 시간</Text>
    </View>
  </View>
);

const AttendanceTableRow = ({
  index,
  startDate,
  endDate,
  learningMinutes,
  isLast = false,
}) => (
  <View style={isLast ? styles.attendanceRowLast : styles.attendanceRow}>
    <View style={styles.attendanceCellFirst}>
      <Text style={styles.cellText}>{index}</Text>
    </View>
    <View style={styles.attendanceCell}>
      <Text style={styles.cellText}>{startDate}</Text>
    </View>
    <View style={styles.attendanceCell}>
      <Text style={styles.cellText}>{endDate}</Text>
    </View>
    <View style={styles.attendanceCellLast}>
      <Text style={styles.cellText}>{learningMinutes}</Text>
    </View>
  </View>
);

const calculatePages = (data, firstPageItems, restPageItems) => {
  const pages = [];

  if (data.length > 0) {
    const firstPageData = data.slice(0, firstPageItems);
    pages.push(firstPageData);

    let remainingData = data.slice(firstPageItems);
    while (remainingData.length > 0) {
      const pageData = remainingData.slice(0, restPageItems);
      pages.push(pageData);

      remainingData = remainingData.slice(restPageItems);
    }
  }

  return pages;
};

const Footer = () => (
  <View style={styles.footerContainer}>
    <View style={styles.footerInfoContainer}>
      <Text style={styles.footerLabel}>※ 학습 시간 산출 기준</Text>
      <Text style={styles.footerValue}>
        학습 인정 시간은 고용24에서 공식 인정되는 훈련 시간을 기준으로
        산출됩니다.
      </Text>
    </View>
    <View style={styles.footerSignatureContainer}>
      <Text style={styles.signatureName}>팀스파르타(주)</Text>
      <Image src={signatureImage} style={styles.signature} />
      <Text style={styles.dateText}>
        발급일 : {format(new Date(), "yyyy.MM.dd")}
      </Text>
    </View>
  </View>
);

export const AttendanceTemplate = ({ enrollmentData, attendanceData = [] }) => {
  const totalLearningMinutes = attendanceData.reduce(
    (total, data) => total + (data?.learningMinutes || 0),
    0
  );

  const companyTableData = [
    { label: "상호", value: "팀스파르타 주식회사" },
    { label: "대표", value: "이범규" },
    {
      label: "업태",
      value: "교육서비스",
    },
    {
      label: "연락처",
      value: "1522-8016",
    },
    {
      label: "종목",
      value: "기타 교육지원 서비스업",
    },
    {
      label: "소재지",
      value: "서울특별시 강남구 테헤란로 44길 8, 12층",
    },
  ];

  const userTableData = [
    { label: "이름", value: enrollmentData.name },
    { label: "연락처", value: formatPhoneNumber(enrollmentData.phone) },
    { label: "훈련 과정명", value: enrollmentData.courseName },
    {
      label: "수강 기간",
      value: `${enrollmentData.coursePeriod} (${enrollmentData.totalDays}일, 총 ${enrollmentData.certifiedLearningHours}시간)`,
    },
    { label: "진도율", value: `${enrollmentData.progress}%` },
    {
      label: "총 학습 시간",
      value: `${Math.floor(totalLearningMinutes / 60)}시간 ${
        totalLearningMinutes % 60
      }분`,
    },
  ];

  const formattedAttendanceData =
    attendanceData?.map((data, index) => ({
      index: attendanceData.length - index,
      startDate: format(data?.startDate, "yyyy.MM.dd HH:mm:ss"),
      endDate: format(data?.endDate, "yyyy.MM.dd HH:mm:ss"),
      learningMinutes: `${Math.floor(data?.learningMinutes / 60)}시간 ${
        data?.learningMinutes % 60
      }분`,
    })) || [];

  const ITEMS_PER_PAGE_FIRST = 24;
  const ITEMS_PER_PAGE_REST = 26; // 2 more items for subsequent pages

  const hasAttendanceData = formattedAttendanceData.length > 0;

  // Use the helper function to calculate pages
  const pages = calculatePages(
    formattedAttendanceData,
    ITEMS_PER_PAGE_FIRST,
    ITEMS_PER_PAGE_REST
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={logoImage} style={styles.logo} />

        <Text style={styles.title}>스파르타코딩클럽 출석 확인증</Text>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableHeaderText}>공급자</Text>
          </View>
          {companyTableData?.map((row, index) => (
            <TableRow
              key={row.label}
              label={row.label}
              value={row.value}
              isLast={index === companyTableData?.length - 1}
            />
          ))}
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableHeaderText}>수강생 정보</Text>
          </View>
          {userTableData?.map((row, index) => (
            <TableRow
              key={row.label}
              label={row.label}
              value={row.value}
              isLast={index === userTableData?.length - 1}
            />
          ))}
        </View>

        {!hasAttendanceData && <Footer />}
      </Page>

      {hasAttendanceData &&
        pages.map((pageData, pageIndex) => (
          <Page key={pageIndex} size="A4" style={styles.page}>
            <View style={styles.noMarginTable}>
              {pageIndex === 0 && (
                <>
                  <View style={styles.tableHeader}>
                    <Text style={styles.tableHeaderText}>학습 기록</Text>
                  </View>
                  <AttendanceTableHeader />
                </>
              )}
              {pageData.map((data, dataIndex) => (
                <AttendanceTableRow
                  key={dataIndex}
                  index={data.index}
                  startDate={data.startDate}
                  endDate={data.endDate}
                  learningMinutes={data.learningMinutes}
                  isLast={dataIndex === pageData.length - 1}
                />
              ))}
            </View>

            {pageIndex === pages.length - 1 && <Footer />}
          </Page>
        ))}
    </Document>
  );
};
