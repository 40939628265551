import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HomeworkContents } from "../../components/organisms/HomeworkV3/HomeworkContents/HomeworkContents";
import { HomeworkGnb } from "../../components/organisms/HomeworkV3/HomeworkGnb/HomeworkGnb";
import { KDCHomeworkNoticeToastMessage } from "../../components/organisms/HomeworkV3/KDCHomeworkNoticeToastMessage/KDCHomeworkNoticeToastMessge";
import { LoadingRtan } from "../../components/organisms/LoadingRtan/LoadingRtan";
import { VerificationModal } from "../../components/organisms/Modal/VerificationModal/VerificationModal";
import { useLogHomeworkPageView } from "../../hooks/homework/useLogHomeworkClick";
import { useHomeworkData } from "../../hooks/homeworkV3/useHomeworkData";
import { useGetEnrolled } from "../../queries/homeworkV3";
import { useTrackLastSeenLecture } from "../../queries/lecture";
import { enrolledHomeworkDetailsAtom } from "../../stores/enrolledHomeworkDetails";
import { isLectureListOpen } from "../../stores/homeworkPageV3Atom";
import { SideBar } from "../LecturePageV3/SideBar/SideBar";
import { useHandlePlayerCss, useUser } from "../LecturePageV3/useInitialize";
import * as S from "./HomeworkPageV3.style";

export const HomeworkPageV3 = ({ match }) => {
  const user = useUser();
  const { enrolled_homework_id } = useParams();
  const [isOpenList] = useAtom(isLectureListOpen);
  const [, setOpenBuffer] = useState(false);
  const { enrolledId, isFeedbackRequired, enrolledsHomeworkDetails } =
    useHomeworkData(enrolled_homework_id);
  const { data: enrolledData } = useGetEnrolled(enrolledId);
  const [, setEnrolledHomeworkDetails] = useAtom(enrolledHomeworkDetailsAtom);

  useEffect(() => {
    if (enrolledsHomeworkDetails?.length > 0) {
      setEnrolledHomeworkDetails(enrolledsHomeworkDetails);
    }
  }, [enrolledsHomeworkDetails]);
  useTrackLastSeenLecture(match, user, "homework");
  useLogHomeworkPageView();
  useHandlePlayerCss(setOpenBuffer);

  if (!enrolledData?.ok) return <LoadingRtan />;
  return (
    <>
      <VerificationModal />
      <KDCHomeworkNoticeToastMessage
        isOpenList={isOpenList}
        isFeedbackRequired={isFeedbackRequired}
      />
      <HomeworkGnb isOpenList={isOpenList} enrolledData={enrolledData} />
      <S.HomeworkContentsWrapper isOpenList={isOpenList}>
        <HomeworkContents isOpenList={isOpenList} />
        <SideBar courseId={enrolledData?.context?.course_id} />
      </S.HomeworkContentsWrapper>
    </>
  );
};
