import { useEffect } from "react";
import { IN_HOUSE_URL } from "../../_consts";

export const PaymentPage_v2 = () => {
  useEffect(() => {
    alert("결제 페이지 주소가 변경되었습니다. 처음부터 다시 시도해주세요.");

    window.location.replace(IN_HOUSE_URL.국비신청내역);
  }, []);

  return null;
};
