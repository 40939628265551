import { BlobProvider } from "@react-pdf/renderer";
import { differenceInDays, startOfDay } from "date-fns";
import React from "react";
import { sendLog } from "../../../../businesslogics/logging";
import { usePdfDownload } from "../../../../hooks/certificate/usePdfDownload";
import { useIsMobile } from "../../../../hooks/device";
import {
  sendLogCertificate,
  useGetValidEnrollments,
  useReceipt,
} from "../../../../queries/mypage";
import { AttendanceTemplate } from "../CertificateTemplates/AttendanceTemplate";
import { CompletionCertificateTemplate } from "../CertificateTemplates/CompletionTemplate";
import { EnrollmentCertificate } from "../CertificateTemplates/EnrollmentTemplate";
import { DownloadIcon, Receipt } from "../Icons/Icons";
import { CertificateCardMobile } from "./CertificateCardMobile";
import {
  getCertificateCourseName,
  getListDisplayCourseName,
} from "./businessLogic";
import * as S from "./Certificates.style";

export const adjustEndDateIfMidnight = (dateObj) => {
  // 한국 시간대로 시간 확인
  const koreaTime = dateObj.toLocaleString("en-US", { timeZone: "Asia/Seoul" });
  const koreaDate = new Date(koreaTime);

  if (
    koreaDate.getHours() === 0 &&
    koreaDate.getMinutes() === 0 &&
    koreaDate.getSeconds() === 0
  ) {
    const adjustedDate = new Date(dateObj);
    adjustedDate.setDate(adjustedDate.getDate() - 1);
    return adjustedDate;
  }

  return dateObj;
};

export const parsePeriod = (startDate, endDate) => {
  const parseAndReplace = (string) => {
    const date = new Date(string);
    return date
      .toLocaleDateString("ko-KR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replaceAll(". ", ".")
      .slice(0, -1);
  };

  const start = parseAndReplace(startDate);

  const endDateObj = new Date(endDate);
  const adjustedEndDateObj = adjustEndDateIfMidnight(endDateObj);
  const end = parseAndReplace(adjustedEndDateObj.toISOString());

  return `${start} ~ ${end.split(".")[0] === "2099" ? "평생" : end}`;
};

export const isReceiptDownloadable = (receiptInfo) => {
  if (!receiptInfo) return false;
  return !!(receiptInfo.tid || receiptInfo.receiptUrl);
};

export const getReceipt = (enrolled) => {
  const tid = enrolled.tid;
  const receipt_url = enrolled.receipt_url;
  const isTidNotPossible = tid === "" || !tid;
  const isReceiptUrlNotPossible = receipt_url === "" || !receipt_url;
  if (isTidNotPossible && isReceiptUrlNotPossible) return;
  sendLog(
    "scc_mypagePage_click_certificate",
    {
      certificate_type: "영수증",
      course_title: enrolled.course_title,
      course_id: enrolled.course._id,
      round: enrolled.round_title,
      round_id: enrolled.enrolled.round_id,
      is_success: true,
    },
    "",
    true
  );
  if (receipt_url) {
    window.open(receipt_url);
  } else {
    window.open(
      `https://npg.nicepay.co.kr/issue/IssueLoader.do?TID=${tid}&type=0`,
      "_blank"
    );
  }
};

// PDF 렌더링 컴포넌트 분리
const CompletionPDF = React.memo(({ enrollment }) => {
  return (
    <CompletionCertificateTemplate
      enrollmentData={{
        name: enrollment.name,
        courseName: getCertificateCourseName(enrollment),
        coursePeriod: parsePeriod(enrollment.startDate, enrollment.endDate),
        isKdc: enrollment.isKdc,
      }}
    />
  );
});

const AttendancePDF = React.memo(({ enrollment }) => {
  return (
    <AttendanceTemplate
      enrollmentData={{
        name: enrollment.name,
        courseName: enrollment.courseName,
        phone: enrollment.phone,
        coursePeriod: parsePeriod(enrollment.startDate, enrollment.endDate),
        totalDays:
          differenceInDays(
            startOfDay(adjustEndDateIfMidnight(new Date(enrollment.endDate))),
            startOfDay(new Date(enrollment.startDate))
          ) + 1,
        isKdc: enrollment.isKdc,
        progress: enrollment?.attendanceInfo?.progressRate,
        certifiedLearningHours: enrollment?.kdcInfo?.certifiedLearningHours,
      }}
      attendanceData={enrollment?.attendanceInfo?.attendanceDetail}
    />
  );
});

// 수료증 컴포넌트 분리
export const DesktopCompletionCertificate = ({ enrollment }) => {
  const { retryCount, retryKey, handleRetry, handleDownload } = usePdfDownload({
    maxRetries: 3,
  });

  if (!enrollment.canIssueCompletionCertificate) {
    return (
      <S.Td>
        <S.DownloadIconContainer>
          <S.DownloadContainer
            isActive={false}
            onClick={() => sendLogCertificate("수료증", enrollment.systemType)}
          >
            <S.IconWrapper>
              <DownloadIcon />
            </S.IconWrapper>
          </S.DownloadContainer>
        </S.DownloadIconContainer>
      </S.Td>
    );
  }

  // 데이터 유효성 검사
  const hasValidData =
    enrollment &&
    enrollment.name &&
    enrollment.courseName &&
    enrollment.startDate &&
    enrollment.endDate;

  return (
    <S.Td>
      <S.DownloadIconContainer>
        <BlobProvider
          key={retryKey}
          document={<CompletionPDF enrollment={enrollment} />}
        >
          {({ blob, url, loading, error }) => {
            if (error) {
              console.error("수료증 PDF 생성 오류:", error);

              if (retryCount < 3) {
                setTimeout(() => {
                  handleRetry();
                }, 500);
              }
            }

            const isButtonActive =
              hasValidData && !loading && (!error || retryCount >= 3);

            return (
              <S.DownloadContainer
                isActive={isButtonActive}
                onClick={() =>
                  isButtonActive &&
                  handleDownload(
                    blob,
                    url,
                    loading,
                    error,
                    hasValidData,
                    `수료증_${enrollment.courseName}.pdf`,
                    "수료증",
                    enrollment.systemType
                  )
                }
              >
                {loading || (error && retryCount < 3) ? (
                  <S.SolvingImg src={"/v2/assets/icons/loading_spinner.png"} />
                ) : (
                  <S.IconWrapper>
                    <DownloadIcon />
                  </S.IconWrapper>
                )}
              </S.DownloadContainer>
            );
          }}
        </BlobProvider>
      </S.DownloadIconContainer>
    </S.Td>
  );
};

export const DesktopAttendanceCertificate = ({ enrollment }) => {
  const { retryCount, retryKey, handleRetry, handleDownload } = usePdfDownload({
    maxRetries: 3,
  });

  if (!enrollment.isKdc) return null;

  if (!enrollment?.canIssueAttendanceCertificate) {
    return (
      <S.Td>
        <S.DownloadIconContainer>
          <S.DownloadContainer isActive={false}>
            <S.IconWrapper>
              <DownloadIcon />
            </S.IconWrapper>
          </S.DownloadContainer>
        </S.DownloadIconContainer>
      </S.Td>
    );
  }

  if (enrollment?.systemType === "NEW") {
    // 데이터 유효성 검사
    const hasValidData =
      enrollment &&
      enrollment.name &&
      enrollment.courseName &&
      enrollment.startDate &&
      enrollment.endDate &&
      Array.isArray(enrollment?.attendanceInfo?.attendanceDetail);

    return (
      <S.Td>
        <S.DownloadIconContainer>
          <BlobProvider
            key={retryKey}
            document={<AttendancePDF enrollment={enrollment} />}
          >
            {({ blob, url, loading, error }) => {
              // 오류 발생 시 콘솔에 기록하고 재시도
              if (error) {
                console.error("PDF 생성 오류:", error);

                // 자동 재시도 설정 (오류 발생 시 500ms 후 재시도)
                if (retryCount < 3) {
                  setTimeout(() => {
                    handleRetry();
                  }, 500);
                }
              }

              // 버튼 활성화 상태 결정 (재시도 중이면 비활성화)
              const isButtonActive =
                hasValidData && !loading && (!error || retryCount >= 3);

              return (
                <S.DownloadContainer
                  isActive={isButtonActive}
                  onClick={() =>
                    isButtonActive &&
                    handleDownload(
                      blob,
                      url,
                      loading,
                      error,
                      hasValidData,
                      `출석증_${enrollment.courseName}.pdf`,
                      "출석증",
                      enrollment.systemType
                    )
                  }
                >
                  {loading || (error && retryCount < 3) ? (
                    <S.SolvingImg
                      src={"/v2/assets/icons/loading_spinner.png"}
                    />
                  ) : (
                    <S.IconWrapper>
                      <DownloadIcon />
                    </S.IconWrapper>
                  )}
                </S.DownloadContainer>
              );
            }}
          </BlobProvider>
        </S.DownloadIconContainer>
      </S.Td>
    );
  }

  return (
    <S.Td>
      <S.DownloadIconContainer>
        <S.DownloadContainer
          isActive={true}
          onClick={() => {
            sendLogCertificate("출석증", enrollment.systemType);
            alert(
              "2025년 2월 24일 이전 구매한 과정은 마이페이지에서 발급이 불가합니다. 우측 하단 문의하기 버튼을 통해 문의해주세요."
            );
          }}
        >
          <S.IconWrapper>
            <DownloadIcon />
          </S.IconWrapper>
        </S.DownloadContainer>
      </S.DownloadIconContainer>
    </S.Td>
  );
};

export const CertifiRow = ({ enrollment }) => {
  const { retryCount, retryKey, handleRetry, handleDownload } = usePdfDownload({
    maxRetries: 3,
  });
  const { receiptInfo, handleReceiptClick } = useReceipt(enrollment);

  return (
    <S.CourseInfoRow>
      {enrollment.isCompleted ? (
        <S.CourseStatusTd>
          <S.CourseCompleted>수료</S.CourseCompleted>
        </S.CourseStatusTd>
      ) : (
        <S.CourseStatusTd>
          <S.CourseEnded>미수료</S.CourseEnded>
        </S.CourseStatusTd>
      )}

      <S.CourseTitleTd>
        <S.CourseTitleContainer>
          <S.CourseTitle>{getListDisplayCourseName(enrollment)}</S.CourseTitle>
          <S.CoursePeriod>
            {parsePeriod(enrollment.startDate, enrollment.endDate)}
          </S.CoursePeriod>
        </S.CourseTitleContainer>
      </S.CourseTitleTd>

      {/* 영수증 */}
      <S.Td onClick={handleReceiptClick}>
        <S.DownloadContainer isActive={isReceiptDownloadable(receiptInfo)}>
          <S.IconWrapper>
            <Receipt />
          </S.IconWrapper>
        </S.DownloadContainer>
      </S.Td>

      {/* 수강증 */}
      <S.Td>
        <S.DownloadContainer
          isActive={enrollment.canIssueEnrollmentCertificate}
          onClick={() => {
            if (!enrollment.canIssueEnrollmentCertificate) {
              sendLogCertificate("수강증", enrollment.systemType);
              return;
            }
          }}
        >
          {enrollment.canIssueEnrollmentCertificate ? (
            <BlobProvider
              key={retryKey}
              document={
                <EnrollmentCertificate
                  enrollmentData={{
                    name: enrollment.name,
                    courseName: getCertificateCourseName(enrollment),
                    coursePeriod: parsePeriod(
                      enrollment.startDate,
                      enrollment.endDate
                    ),
                    isKdc: enrollment.isKdc,
                  }}
                />
              }
            >
              {({ blob, url, loading, error }) => {
                // 오류 발생 시 재시도 로직
                if (error) {
                  console.error("수강증 PDF 생성 오류:", error);

                  // 최대 시도 횟수에 도달하지 않았다면 재시도
                  if (retryCount < 3) {
                    setTimeout(() => {
                      handleRetry();
                    }, 500);
                  }
                }

                // 데이터 유효성 검사
                const hasValidData =
                  enrollment &&
                  enrollment.name &&
                  enrollment.courseName &&
                  enrollment.startDate &&
                  enrollment.endDate;

                // 버튼 활성화 상태 결정
                const isButtonActive =
                  hasValidData && !loading && (!error || retryCount >= 3);

                return (
                  <div
                    onClick={() =>
                      isButtonActive &&
                      handleDownload(
                        blob,
                        url,
                        loading,
                        error,
                        hasValidData,
                        `수강증_${enrollment.courseName}.pdf`,
                        "수강증",
                        enrollment.systemType
                      )
                    }
                  >
                    {loading || (error && retryCount < 3) ? (
                      <S.SolvingImg
                        src={"/v2/assets/icons/loading_spinner.png"}
                      />
                    ) : (
                      <S.IconWrapper>
                        <DownloadIcon />
                      </S.IconWrapper>
                    )}
                  </div>
                );
              }}
            </BlobProvider>
          ) : (
            <S.IconWrapper>
              <DownloadIcon />
            </S.IconWrapper>
          )}
        </S.DownloadContainer>
      </S.Td>

      {/* 수료증 */}
      <DesktopCompletionCertificate enrollment={enrollment} />

      {/* 출석증 */}
      <DesktopAttendanceCertificate enrollment={enrollment} />
    </S.CourseInfoRow>
  );
};

const BulletList = () => {
  return (
    <S.BulletList>
      <S.BulletItem>
        수강증/출석증 : 수강 시작일 이후 / 수료증 : 수료 조건 충족 시 다운로드
      </S.BulletItem>
      <S.BulletItem>파일 다운로드는 최대 15초 소요될 수 있습니다.</S.BulletItem>
      <S.BulletItem>출석증은 국비지원 강의에 한하여 제공합니다.</S.BulletItem>
    </S.BulletList>
  );
};

export const Certificates = () => {
  const { data: validEnrollments } = useGetValidEnrollments();
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile ? (
        <>
          <S.CertifiDesktopWrapper>
            <table>
              <S.CertifiHeaderRows>
                <S.CertifiCaution colSpan={"2"}>
                  <BulletList />
                </S.CertifiCaution>
                <S.CertifiHeader>영수증</S.CertifiHeader>
                <S.CertifiHeader>수강증</S.CertifiHeader>
                <S.CertifiHeader>수료증</S.CertifiHeader>
                <S.CertifiHeader>출석증</S.CertifiHeader>
              </S.CertifiHeaderRows>
              {validEnrollments?.map((enrollment, idx) => {
                return (
                  <CertifiRow
                    key={"certifirow" + enrollment.enrollmentId}
                    enrollment={enrollment}
                  />
                );
              })}
            </table>
          </S.CertifiDesktopWrapper>
        </>
      ) : (
        <>
          <S.CertificateMobileWrapper>
            <S.CertifiMaxwidthWrapper>
              <S.CertifiMobileTitle>증명서</S.CertifiMobileTitle>
              <S.CertifiMobileSubTitle>
                <BulletList />
              </S.CertifiMobileSubTitle>
              <S.CertifiCardContainer>
                {validEnrollments?.map((enrollment) => {
                  return (
                    <CertificateCardMobile
                      key={"certificardmobile" + enrollment.enrollmentId}
                      enrollment={enrollment}
                    />
                  );
                })}
              </S.CertifiCardContainer>
            </S.CertifiMaxwidthWrapper>
          </S.CertificateMobileWrapper>
        </>
      )}
    </>
  );
};
