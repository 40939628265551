import { differenceInDays, parseISO } from "date-fns";

export const getListDisplayCourseName = (enrollment) => {
  return enrollment.isKdc && enrollment.kdcInfo
    ? `${enrollment.courseName} ${enrollment.kdcInfo.degree}회차`
    : enrollment.courseName;
};

export const getCertificateCourseName = (enrollment) => {
  if (enrollment.isKdc && enrollment.kdcInfo) {
    const daysDifference =
      differenceInDays(
        parseISO(enrollment.kdcInfo.programEndDate),
        parseISO(enrollment.kdcInfo.programStartDate)
      ) + 1;

    const transformedCourseName = `${enrollment.courseName} ${enrollment.kdcInfo.degree}회차 (총 ${daysDifference}일, ${enrollment.kdcInfo.certifiedLearningHours}시간)`;
    return transformedCourseName;
  }

  return enrollment.courseName;
};
