import privateInstance from "../../../../_helper/axios";

export async function getPresignedUrlFromApi(fileKey, fileName) {
  try {
    const response = await privateInstance.post("/homeworks/presigned_url", {
      key: fileKey,
      fileName: fileName,
    });
    const responseData = response?.data;

    if (!responseData.ok) {
      throw new Error("URL 생성에 실패했습니다");
    }

    return responseData.presignedUrl;
  } catch (error) {
    console.error("Presigned URL 요청 오류:", error);
    throw error;
  }
}

export async function downloadHomeworkDetailFile(id, fileExt, filename) {
  try {
    const fileKey = `homework_detail/${id}.${fileExt}`;
    const encodedFilename = encodeURI(`${filename}.${fileExt}`);
    const presignedUrl = await getPresignedUrlFromApi(fileKey, encodedFilename);
    window.open(presignedUrl);
  } catch (e) {
    alert("첨부파일 다운로드에 실패했습니다. 파일을 업로드했는지 확인해주세요");
  }
}
