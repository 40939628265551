import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  mainAnchor,
  sccAnchor,
  topAnchors,
  topRightAnchors,
} from "../models/gnbV3.models";
import { GnbMyPage } from "../molecules/GnbMyPage";
import {
  GnbAnchorDesktop,
  GnbTopAnchorDesktop,
  GnbTopRightAnchorDesktop,
} from "../atoms/GnbAnchor.desktop";
import { LoginButton } from "../molecules/LoginButton";
import { GnbMyPageLogo } from "../atoms/GnbMyPageLogo";
import { LineBanner } from "../molecules/LineBanner/LineBanner";
import * as S from "./Gnb.desktop2.style";
import { useClickAnchor } from "../../../businesslogics/_layout/gnb/v2";
import { GnbSpartaLogo } from "../../atoms/_layout/gnb/Gnb.style.svg";
import { showLineBannerAtom } from "../../../stores/showLineBannerAtom";
import isDesktopGnbFixed from "../utils/gnbUtils";

export const GnbDesktopV3 = ({ isLogin }) => {
  const onClickAnchor = useClickAnchor();
  const isFixed = isDesktopGnbFixed();
  const [showTopWrapper, setShowTopWrapper] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showLineBanner] = useAtom(showLineBannerAtom);

  // 스크롤 이벤트 처리
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const isCodingland = window.location.pathname.includes("codingland");

    if (isCodingland) {
      if (currentScrollY < lastScrollY) {
        setShowTopWrapper(true);
      } else {
        setShowTopWrapper(false);
      }
    } else {
      setShowTopWrapper(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <>
      <S.TopWrapper
        fixed={isFixed}
        show={showTopWrapper}
        showLineBanner={showLineBanner}
      >
        <S.InnerContainer>
          <S.TopGnbColumnContainer>
            {topAnchors.map((anchor) => (
              <React.Fragment key={`gnb-desktop-category${anchor.key}`}>
                <S.CursorWrapper>
                  <GnbTopAnchorDesktop
                    key={`gnb-desktop-category${anchor.key}`}
                    anchor={anchor}
                    isCategory={true}
                  />
                </S.CursorWrapper>
              </React.Fragment>
            ))}
          </S.TopGnbColumnContainer>
          <S.TopGnbColumnContainer>
            {topRightAnchors.map((anchor) => (
              <React.Fragment key={`gnb-desktop-category${anchor.key}`}>
                <S.CursorWrapper>
                  <GnbTopRightAnchorDesktop
                    key={`gnb-desktop-category${anchor.key}`}
                    anchor={anchor}
                    isCategory={false}
                  />
                </S.CursorWrapper>
              </React.Fragment>
            ))}
          </S.TopGnbColumnContainer>
        </S.InnerContainer>
      </S.TopWrapper>
      <S.MainWrapper
        fixed={isFixed}
        show={showTopWrapper}
        showLineBanner={showLineBanner}
      >
        <S.InnerContainerBottom>
          <S.ColumnContainer>
            <S.LogoWrapper onClick={() => onClickAnchor(sccAnchor)}>
              <GnbSpartaLogo />
            </S.LogoWrapper>
            {mainAnchor.map((anchor) => (
              <GnbAnchorDesktop
                key={`gnb-desktop-category${anchor.key}`}
                anchor={anchor}
                isCategory={true}
              />
            ))}
          </S.ColumnContainer>
          <S.ColumnContainer>
            {isLogin ? (
              <>
                <GnbMyPageLogo />
                <GnbMyPage />
              </>
            ) : (
              <LoginButton />
            )}
          </S.ColumnContainer>
        </S.InnerContainerBottom>
        <LineBanner />
      </S.MainWrapper>
    </>
  );
};
