import { IN_HOUSE_URL } from "../../../../../_consts";
import { ArrowRightSmall } from "../Icons/Icons";
import * as S from "./SayHello.style";

export const SayHello = ({ userName, showGiftTooltip }) => {
  return (
    <S.AllContainer>
      <S.HelloContainer>
        <S.SayHelloTitle>안녕하세요, {userName}님!</S.SayHelloTitle>
        <S.ClassroomCta
          onClick={() => {
            window.location.href = IN_HOUSE_URL.신_내강의실;
          }}
        >
          {showGiftTooltip && (
            <S.GiftTooltip>
              <S.GiftTooltipText>무료 강의가 지급되었어요!</S.GiftTooltipText>
            </S.GiftTooltip>
          )}
          <S.ClassroomTitle>내 강의실 바로가기</S.ClassroomTitle>
          <S.ClassroomIcon>
            <ArrowRightSmall color={"#ffffff"} />
          </S.ClassroomIcon>
        </S.ClassroomCta>
      </S.HelloContainer>
    </S.AllContainer>
  );
};
