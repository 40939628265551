import { useState } from "react";
import { sendLogCertificate } from "../../queries/mypage";

export const usePdfDownload = ({ maxRetries = 3 }) => {
  const [retryCount, setRetryCount] = useState(0);
  const [retryKey, setRetryKey] = useState(0);

  const handleRetry = () => {
    if (retryCount < maxRetries) {
      console.log(
        `PDF 생성 오류, 재시도 중... (${retryCount + 1}/${maxRetries})`
      );
      setRetryCount((prevCount) => prevCount + 1);
      setRetryKey((prevKey) => prevKey + 1);
    }
  };

  const handleDownload = (
    blob,
    url,
    loading,
    error,
    hasValidData,
    fileName,
    certificateType,
    systemType
  ) => {
    // 오류가 있고 재시도 횟수가 남아있으면 재시도
    if (error && retryCount < maxRetries) {
      handleRetry();
      return;
    }

    // blob이 없거나 데이터가 유효하지 않으면 중단
    if (!blob || !hasValidData) return;

    // 로그 전송 및 다운로드 진행
    sendLogCertificate(certificateType, systemType);

    try {
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (downloadError) {
      console.error("PDF 다운로드 오류:", downloadError);
      alert("PDF 다운로드 중 오류가 발생했습니다. 다시 시도해주세요.");
    }
  };

  return {
    retryCount,
    retryKey,
    handleRetry,
    handleDownload,
  };
};
