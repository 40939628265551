export const PIRATES = "pirates";
export const POINT_REIMBURSE =
  new Date() >= new Date(2023, 2, 20) ? "1만" : "5만";

export const FREE_INSTALLMENT = [
  { card: "하나카드 (하나SK/외환)", installment: "2~3" },
  { card: "비씨카드", installment: "2~6" },
  { card: "현대카드", installment: "2~3" },
  { card: "신한카드", installment: "2~3" },
  { card: "KB국민카드", installment: "2~3" },
  { card: "NH농협카드", installment: "2~4" },
  { card: "우리카드", installment: "2~6" },
  { card: "롯데카드", installment: "2~3" },
  { card: "삼성카드", installment: "2~3" },
];

export const IN_HOUSE_URL = {
  상품탐색: `${process.env.REACT_APP_SCC_URL}/catalog/scc`,
  수업탐색: `${process.env.REACT_APP_SCC_URL}/catalog`,
  구_내강의실: `/classroom`,
  신_내강의실: `${process.env.REACT_APP_ACADEMIA_URL}/classroom`,
  국비신청내역: `${process.env.REACT_APP_SCC_URL}/kdc/registration_status`,
};
