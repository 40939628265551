import * as S from "./MyPage.style";
import { MyBasicinfo } from "../../components/organisms/Mypage/MyBasicinfo";
import { MyVoucher } from "../../components/organisms/Mypage/MyVoucher/MyVoucher";
import { MyCoupon } from "../../components/organisms/Mypage/MyCoupon";
import { MyPoint } from "../../components/organisms/Mypage/MyPoint/MyPoint";
import { Certificates } from "../../components/organisms/Mypage/Certificates/Certificates";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../../../src/_helpers/axios";
import { sendLog } from "../../businesslogics/logging";
import { Gnb } from "../../components/organisms/_layout/gnb/Gnb";
import { SayHello } from "../../components/organisms/Mypage/SayHello/SayHello";
import { LeftMenu } from "../../components/organisms/Mypage/LeftMenu/LeftMenu";
import { useDispatch, useSelector } from "react-redux";
import { getHrdLink } from "../../_state/v3/hrdLink2";
import { fillHyphen, numberWithCommas, params } from "../../_helper";
import { enrolledActions, pointActions } from "../../../_actions";
import { usersActions } from "../../_state/actions";
import { useIsMobile } from "../../hooks/device";
import GiftSnackBar from "../../components/organisms/Mypage/GiftSnackBar/GiftSnackBar";
import { FooterV2 } from "../../../_components/Footer/FooterV2";
import { useCouponCountText } from "../../businesslogics/mypage/mypage.hooks";
import { getUserId } from "../LecturePageV3/useInitialize";
const sections = {
  mybasicinfo: MyBasicinfo,
  myvoucher: MyVoucher,
  mycertificate: Certificates,
  mycoupon: MyCoupon,
  mypoint: MyPoint,
};

export const MyPage = () => {
  const { section } = useParams();
  const { coupon_code, from } = params();
  const history = useHistory();
  const Section = sections[section] || MyPoint;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);
  const userId = getUserId();
  const pointInfo = useSelector((state) => state.pointInfo);
  const vouchers = useSelector((state) => state.vouchers);
  const enrolledDone = useSelector((state) => state.enrolledDone);
  const expirationPoints = useSelector((state) => state.expirationPoints);
  const [showGiftTooltip, setShowGiftTooltip] = useState(false);
  const isMobile = useIsMobile();
  const couponCountText = useCouponCountText(userId);

  useEffect(() => {
    console.log("isMobile:", isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (userId) {
      dispatch(getHrdLink());
      if (!pointInfo.loaded) dispatch(pointActions.getPointInfo());
      if (!vouchers.loaded) dispatch(enrolledActions.getVouchers());
      if (!enrolledDone.loaded) dispatch(enrolledActions.getEnrolledDone());
      if (!expirationPoints.loaded)
        dispatch(usersActions.getExpirationPoints());
    } else {
      // history.push(`/login?next=${getNextUrl()}`);
    }
  }, [userId]);

  useEffect(() => {
    sendLog("scc_mypagePage_view", { tab: section }, "", true);
    if (coupon_code) handleCouponCode();
  }, [section]);

  const _registerCouponDirect = async (coupon_code) => {
    try {
      const resp = await axios.post("/coupons/register", {
        couponCode: coupon_code,
      });
      const { data } = resp;
      alert(data.msg);
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
      }
    }
  };

  const handleCouponCode = () => {
    _registerCouponDirect(coupon_code);
    history.replace("/mypage/mycoupon");
  };

  if (
    !user ||
    !pointInfo.loaded ||
    !vouchers.loaded ||
    !enrolledDone.loaded ||
    !expirationPoints.loaded
  ) {
    return <div />;
  }

  return (
    <>
      <S.MyPageWrapper>
        <Gnb />
        {isMobile && showGiftTooltip && <GiftSnackBar />}
        {!isMobile && (
          <SayHello userName={user.name} showGiftTooltip={showGiftTooltip} />
        )}
        <S.MyPageContainer>
          {!isMobile && (
            <LeftMenu
              point={numberWithCommas(pointInfo.data?.point)}
              couponCount={couponCountText}
              voucherCount={vouchers.data?.context.vouchers.length}
              userEmail={user.email}
              userPhone={`${user.country_code} ${fillHyphen(user.phone)}`}
            />
          )}
          <S.SectionWrapper>
            <Section />
          </S.SectionWrapper>
        </S.MyPageContainer>
        <FooterV2 />
      </S.MyPageWrapper>
      {/*<MarketingAgreementModal CPLogger={CPL} />*/}
    </>
  );
};
