import styled from "@emotion/styled";
import {
  neutralDay,
  mBody1,
  mBody2,
  mCaption2,
  mTitle2,
  wCaption1,
} from "@teamsparta/design-system";
import { device, DisplayResolution } from "../../../../_styles/device";
import React from "react";
import { CategoryLogoSVG } from "../molecules/GnbMyPage.svg";
import {
  useClickAnchor,
  useIsTargetPage,
} from "../../../businesslogics/_layout/gnb/v2";

export const GnbRowMobile = ({
  anchor,
  isProduct = false,
  isAbsolutMatch = false,
  isSub = false,
  onClickHandler = () => {},
}) => {
  const onClickAnchor = useClickAnchor();
  const isMatch = useIsTargetPage(anchor.href, isAbsolutMatch);

  const handleClick = (anchor) => {
    onClickAnchor(anchor);
  };

  return (
    <Wrapper
      isSub={isSub}
      isActive={isMatch}
      isLogout={anchor.name === "로그아웃"}
      onClick={() => {
        handleClick(anchor);
        onClickHandler();
      }}
      className={anchor.className}
    >
      <SubTextWrapper>
        <NameContainer isSub={isSub} isLogout={anchor.name === "로그아웃"}>
          <CategoryLogoSVG category={anchor.key} />
          <NameText isSub={anchor.isSub} isLogout={anchor.name === "로그아웃"}>
            {anchor.name}
          </NameText>
          {anchor.tag && <Tag>{anchor.tag}</Tag>}
        </NameContainer>
        {isProduct && <SubText>{anchor.mobileText}</SubText>}
      </SubTextWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${({ isSub, isLogout }) => (isSub || isLogout ? "8px 0" : "12px 0")};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  font-family: Pretendard;
  width: 100%;
`;

const NameContainer = styled.div`
  font-family: Pretendard;
  ${({ isSub }) => isSub && `${mBody2}; color: ${neutralDay.gray90};`}
  ${({ isLogout }) => isLogout && `${wCaption1}; color: ${neutralDay.gray80};`}
  display: flex;
  align-items: center;
  ${mTitle2};
  color: ${neutralDay.gray100};
  flex-direction: row;
  gap: 8px;
  padding-top: 4px;
  @media ${device.desktop} {
    height: 24px;
  }
`;

const NameText = styled.div`
  display: flex;
  align-items: center;
  ${mTitle2};
  color: ${neutralDay.gray100};
  ${({ isSub, isLogout }) =>
    isSub
      ? "font-weight: 500; font-size: 14px;"
      : isLogout
      ? `font-size: 13px; font-weight: 500; color: ${neutralDay.gray80};`
      : `color: ${neutralDay.gray90};`}
  ${DisplayResolution.TabletAndDesktop} {
    ${mBody1};
    ${({ isSub, isLogout }) =>
      isSub
        ? "font-weight: 500; font-size: 14px;"
        : isLogout
        ? `font-size: 13px; font-weight: 500; color: ${neutralDay.gray80};`
        : `color: ${neutralDay.gray90};`}
  }
`;

const SubTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SubText = styled.div`
  ${mCaption2};
  color: ${neutralDay.gray70};
`;

export const Tag = styled.div`
  display: flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${neutralDay.gray10};
  cursor: pointer;
  color: ${neutralDay.gray70};
  font-feature-settings: "clig" off, "liga" off;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 15px */
`;
