import React from "react";
import { useMovePage } from "../../../../businesslogics/_layout/gnb/v2";
import useWindowDimensions from "../../../../../hooks/useWinddowDimensions";
import { breakpoints } from "../../../../../_styles/device";
import { useIsLoggedInV2 } from "../../../../hooks/useSetAmplitudeUserId";
import { GnbDesktopV3 } from "../../../gnbV3/organisms/Gnb.desktop2";
import { GnbMobileV3 } from "../../../gnbV3/organisms/Gnb.mobile2";

export const Gnb = () => {
  const windowSize = useWindowDimensions();
  const isBDesktop = windowSize.width >= breakpoints.tablet;
  const isLoggedIn = useIsLoggedInV2();
  useMovePage();

  return isBDesktop ? <GnbDesktopV3 isLogin={isLoggedIn} /> : <GnbMobileV3 />;
};
