import styled from "styled-components";
import { breakpoints, Device } from "../../../../../_styles/device";

export const MyPointWrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  font-size: 16px;
  padding: 25px 0px;

  ${Device.Desktop} {
    border: 1px solid #e9ecef;
    border-radius: 24px;

    margin-bottom: 25px;
    width: 100%;
    padding: 28px 40px;

    &.wide {
      border: none;
      box-shadow: none;
    }
  }

  /* @media (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.miniDesktop}px) {
    margin-top: 90px;
  } */
`;

export const PointContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;

  letter-spacing: -0.06em;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  position: relative;
  max-height: none;

  ${Device.Desktop} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const PointTextContainer = styled.div`
  width: 100%;

  img {
    width: 32px;
    height: 32px;
  }

  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.98px;
  color: #212529;

  text-align: center;
  padding: 10px 0px 40px;
  margin-bottom: 17px;
  border-bottom: 1px solid #ced4da;

  ${Device.Desktop} {
    font-size: 35px;
    letter-spacing: -1.2px;
    margin-bottom: 0px;
  }
`;

export const Unit = styled.span`
  font-size: 14px;
`;

export const ExpirationPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const ExpirationDetail = styled.span`
  font-size: 14px;
  line-height: 1.5;
  font-family: "Pretendard";
  color: #e8344e;
  font-weight: 600;
`;

export const ExpirationNotice = styled(ExpirationDetail)`
  color: #6b6e72;
`;

export const PointNotice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 24px;
  width: 100%;
  letter-spacing: -0.06em;

  font-size: 16px;
  line-height: 130%;
  position: relative;

  text-decoration: underline;
  text-underline-offset: 4px;

  cursor: pointer;
  ${Device.Desktop} {
    &:hover::after {
      display: flex;
      justify-content: center;
      align-items: center;

      background-image: url(${"/assets/icons/tooltip_a.png"});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      text-align: left;

      color: white;
      line-height: 18px;
      font-weight: 500;
      letter-spacing: -0.5px;
      padding: 11px 0;
      content: "${({ text }) => text}";

      height: 40px;
      width: 260px;

      top: -10px;
      left: 368px;
      font-size: 12px;
    }
  }
`;

export const PointNoticeDetail = styled.div`
  font-size: 13px;
  color: #868e96;
  line-height: 18px;
  letter-spacing: -0.55px;
  text-align: center;
  margin-top: -13px;
  ${Device.Desktop} {
    display: none;
  }
`;
