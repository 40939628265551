import styled from "styled-components";
import {
  linebannerDesktopHeight,
  lineBannerMobileHeight,
  lineBannerTabletHeight,
} from "../../../../styles/variables";
import { Device } from "../../../../../_styles/device";
import { fontPretendard } from "../../../../styles/font";
import { mBody1, neutralDay, scc, wTitle4 } from "@teamsparta/design-system";

export const BannerWrapper = styled.div`
  background: linear-gradient(90deg, #fff6dd 0%, #fff1f4 100%);
  margin-top: -1px;
  white-space: nowrap;
  height: ${({ show }) => (show ? `${lineBannerMobileHeight}px` : 0)};
  overflow: hidden;
  transition: height 0.4s;
  position: relative;
  z-index: 0;
  min-width: 360px;

  ${Device.TabletAndDesktop} {
    height: ${({ show }) => (show ? `${lineBannerTabletHeight + 50}px` : 0)};
  }
`;

export const Banner = styled.div`
  padding: 12px 16px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: flex-start;

  ${Device.TabletAndDesktop} {
    padding: 16px;
    max-width: 1184px;
    margin: auto;
    display: flex;
    align-items: center;
  }
`;

export const BannerTitle = styled.a`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: ${fontPretendard};
  font-weight: 700;
  line-height: 1.5;
  color: white;
  font-size: 14px;
  gap: 4px;

  ${Device.TabletAndDesktop} {
    font-size: 16px;
    gap: 6px;
  }
`;

export const EduwillImgs = styled.img`
  width: ${({ isLeftOne }) => (isLeftOne ? 68 : 94)}px;
  height: ${lineBannerMobileHeight};
  position: absolute;
  ${({ isLeftOne }) => (isLeftOne ? "left: 0" : "right: 0")};
  bottom: 0;
  z-index: -1;

  ${Device.TabletAndDesktop} {
    position: inherit;
    width: ${({ isLeftOne }) => (isLeftOne ? 86 : 122)}px;
    height: ${linebannerDesktopHeight};
    margin: ${({ isLeftOne }) => (isLeftOne ? "0 7.5px 0 0" : "0 0 0 2.5px")};
  }
`;

export const BannerTitleContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  ${Device.TabletAndDesktop} {
    flex-direction: row;
    gap: 16px;
  }
`;

export const CollaborationPill = styled.div`
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  padding: 2px 8px;
  align-items: center;
  div {
    background: linear-gradient(90deg, #fcbd18 0%, #e8344e 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${Device.TabletAndDesktop} {
    padding: 2px 12px;
  }
`;

export const BannerTitleText = styled.div`
  display: flex;
  align-items: center;
  ${mBody1};
  color: ${neutralDay.gray90};

  ${Device.TabletAndDesktop} {
    ${wTitle4};
    color: ${neutralDay.gray90};
  }

  span {
    line-height: 1;
    color: ${scc.red100};
    margin-left: 4px;
  }
`;

export const TextRow = styled.div`
  display: flex;
  align-items: center;
`;
export const AccentColor = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ff9ea9;
`;

export const TimeLeft = styled.span`
  text-align: center;
  white-space: nowrap;
  padding: 4px 6px;
  border: 1px solid white;
  border-radius: 6px;
  color: white;
  width: 100px;

  ${Device.TabletAndDesktop} {
    width: 115px;
  }
`;

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : 0)};

  ${Device.TabletAndDesktop} {
    padding: ${({ marginRight }) =>
      marginRight
        ? `auto ${marginRight} auto 0`
        : `auto 0 auto ${marginRight}`};
    justify-content: ${({ marginRight }) => (marginRight ? `left` : `right`)};
  }
`;

export const CTA = styled.div`
  width: 92px;
  color: #141617;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  gap: 10px;
  background: white;
  border-radius: 100px;
  font-weight: 700;
  font-size: 14px;
`;
