import * as S from "./HomeworkQuestion.style";
import React from "react";
import { ShortFormQuestion } from "./ShortFormQuestion/ShortFormQuestion";
import { HomeworkNotion } from "../HomeworkNotion/HomeworkNotion";
import { downloadHomeworkDetailFile } from "../HomeworkNotion/s3Service";

export const HomeworkQuestion = ({ homeworkDetail }) => {
  const { title, description } = homeworkDetail;
  const notionUrl = homeworkDetail?.notionUrl;
  const handleDownloadHomeworkDetailFile = async () => {
    await downloadHomeworkDetailFile(
      homeworkDetail?._id,
      homeworkDetail?.file_ext,
      homeworkDetail?.filename
    );
  };

  return (
    <S.HomeworkQuestionContainer>
      <S.QuestionTag>문제</S.QuestionTag>
      <S.QuestionTitle>{title}</S.QuestionTitle>
      {notionUrl ? (
        <HomeworkNotion
          homeworkDetail={homeworkDetail}
          handleDownloadHomeworkDetailFile={handleDownloadHomeworkDetailFile}
        />
      ) : (
        <ShortFormQuestion description={description} />
      )}
    </S.HomeworkQuestionContainer>
  );
};
