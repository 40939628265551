import styled from "@emotion/styled";

import { neutralDay, wBody1, wCaption1 } from "@teamsparta/design-system";
import {
  useClickAnchor,
  useIsTargetPage,
} from "../../../businesslogics/_layout/gnb/v2";
import { sccAnchor } from "../models/gnbV3.models";

export const GnbAnchorDesktop = ({ anchor, isCategory = false }) => {
  const onClickAnchor = useClickAnchor();
  const isMatch = useIsTargetPage(anchor.href);

  const handleClick = () => {
    onClickAnchor(anchor);
  };

  return (
    <GnbAnchor isLighter={!isCategory} isActive={isMatch} onClick={handleClick}>
      {anchor.name}
      {anchor.tag && <Tag>{anchor.tag}</Tag>}
    </GnbAnchor>
  );
};

export const GnbTopAnchorDesktop = ({ anchor, isCategory = false }) => {
  const onClickAnchor = useClickAnchor();

  return (
    <GnbTopAnchor
      isLighter={!isCategory}
      isActive={anchor.key === sccAnchor.key}
      onClick={() => onClickAnchor(anchor)}
    >
      {/* <Text as="p" font={"captionM"}> */}
      {anchor.name}
      {/* </Text> */}
    </GnbTopAnchor>
  );
};

export const GnbTopRightAnchorDesktop = ({ anchor, isCategory = false }) => {
  const onClickAnchor = useClickAnchor();

  return (
    <GnbTopRightAnchor
      isLighter={!isCategory}
      isActive={anchor.key === sccAnchor.key}
      onClick={() => onClickAnchor(anchor)}
    >
      {/* <Text as="p" font={"captionM"}> */}
      {anchor.name}
      {/* </Text> */}
    </GnbTopRightAnchor>
  );
};

const GnbAnchor = styled.div`
  ${wBody1};
  padding: 9px 10px 8px;
  color: ${neutralDay.black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  :hover {
    display: inline-flex;
    padding: 9px 10px 8px;
    align-items: center;
    border-radius: 6px;
    background: ${neutralDay.gray5};
  }
`;

const GnbTopAnchor = styled.div`
  ${wCaption1};
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${({ isActive }) =>
    isActive ? neutralDay.gray90 : neutralDay.gray60};
  background-color: ${({ isActive }) =>
    isActive ? neutralDay.white : undefined};
  padding: 5px 12px;
`;

const GnbTopRightAnchor = styled.div`
  ${wCaption1};
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${({ isActive }) =>
    isActive ? neutralDay.gray90 : neutralDay.gray60};
  background-color: ${({ isActive }) =>
    isActive ? neutralDay.white : undefined};
  padding: 4px 6px;
`;

export const Tag = styled.div`
  display: flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${neutralDay.gray10};
  color: ${neutralDay.gray70};
  cursor: pointer;
  color: ${neutralDay.gray10};
  font-feature-settings: "clig" off, "liga" off;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 15px */
`;
