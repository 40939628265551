import styled from "styled-components";
import { breakpoints, Device } from "../../../_styles/device";

export const MyPageWrapper = styled.div`
  width: 100%;
`;

export const MyPageContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  ${Device.Desktop} {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0 0 150px 20px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 50px;
  }
`;

export const SectionWrapper = styled.div`
  ${Device.Desktop} {
    margin-top: 0;
    margin-left: 56px;
    width: 894px;
    margin-right: 24px;
  }
`;
