const pagesForNotFixedGnb = [
  "catalog",
  "nb",
  "online",
  "blog",
  "coding1010",
  "sparthon4",
  "online-preview",
  "exhibition",
  "product",
  "kdc",
];

export default function isDesktopGnbFixed() {
  if (typeof window === "undefined") {
    return false;
  }
  const path = window.location.pathname;
  const textAfterSlash = path.split("/")[1];

  return !pagesForNotFixedGnb.includes(textAfterSlash);
}
