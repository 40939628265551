import { useEffect, useRef, useState } from "react";
import {
  getCookie,
  GOV_DONE_DAILY_LIMIT,
  validateObjectId,
} from "../../_helper";
import { createEventId, history, params } from "../../../_helpers";
import { sendLog } from "../../businesslogics/logging";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetCodeSnippets,
  useGetEnrolled,
  useGetEnrolledDetail,
  useGetEnrolledDetails,
  useGetEnrolledProgress,
  useGetExpired,
  useGetFeatureFlags,
  useGetLectureV2,
  useGetMaterial,
  useGetPoint,
  useGetRound,
  useGetRoundWeeks,
} from "../../_state/actions";
import { useGetCourse } from "../../../_actions";
import useWindowDimensions from "../../../hooks/useWinddowDimensions";
import { size } from "../../../_styles/device";
import {
  enrolledService,
  getCodeExamples,
  govService,
} from "../../_state/services";
import { useToastMessage } from "../LecturePage/LecLNB/Toast/Toast";
import { useAtom } from "jotai";
import { forceUpdateAtom } from "../../stores/updateEnrolledDetails";
import { isLectureListOpen } from "../../stores/homeworkPageV3Atom";
import {
  badgeAtom,
  deltaAtom,
  modalTypeAtom,
} from "../../stores/lectureModals";
import { FreeCourses } from "./CardQuizBanner/FreeCourses.data";
import { useNbSkipModalData } from "./useNbModal";
import { useQuery } from "react-query";
import { useFindPreviousDetail } from "../../hooks/roadmap/useFindPreviousDetailId";
import { useSyllabusUnit } from "../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { useCourseId } from "../../hooks/useProductName";

export const useInitializeLectureWithIds = (enrolled_id, detail_id) => {
  const { course_id } = params();
  const featureFlags = useSelector((state) => state.featureFlags);
  const course = useSelector((state) => state.course);
  const enrolled = useSelector((state) => state.enrolled);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const enrolledDetails = useSelector((state) => state.enrolledDetails);
  const round = useSelector((state) => state.round);
  const roundWeeks = useSelector((state) => state.roundWeeks);
  const lecture_v2 = useSelector((state) => state.lecture_v2);
  const getEnrolled = useGetEnrolled(enrolled_id);
  const getEnrolledDetail = useGetEnrolledDetail(enrolled_id, detail_id);
  const getEnrolledDetails = useGetEnrolledDetails(enrolled_id);
  const getEnrolledProgress = useGetEnrolledProgress(enrolled_id);
  const getRoundWeeks = useGetRoundWeeks(enrolled_id);
  const getCourse = useGetCourse(course_id || enrolled.data?.course_id);
  const getFeatureFlags = useGetFeatureFlags();
  const getLectureV2 = useGetLectureV2(enrolledDetail.data?.lecture_id);
  const getRound = useGetRound(enrolled.data?.round_id);
  const dispatch = useDispatch();
  useEffect(() => {
    getEnrolled(dispatch);
    getEnrolledDetail(dispatch);
    getEnrolledDetails(dispatch);
    getCourse(dispatch);
    getRoundWeeks(dispatch);
    getFeatureFlags(dispatch);
    getEnrolledProgress(dispatch);
  }, [enrolled_id, detail_id]);
  useEffect(() => {
    if (enrolled.loaded && enrolledDetail.loaded) {
      sendLog(
        "scc_lecturePage_view",
        { ...enrolled.data, ...enrolledDetail.data },
        createEventId()
      );
      getRound(dispatch);
      getLectureV2(dispatch);
    }
  }, [enrolled.loaded, enrolledDetail.loaded]);
  return (
    enrolledDetail.loaded &&
    enrolledDetails.loaded &&
    enrolled.loaded &&
    featureFlags.loaded &&
    lecture_v2.loaded &&
    course.loaded &&
    round.loaded &&
    roundWeeks.loaded
  );
};

export const useInitializeLecture = (match) => {
  const { enrolled_id, detail_id } = match.params;
  return useInitializeLectureWithIds(enrolled_id, detail_id);
};

export const useIsFirstLectureOfGovIntro = () => {
  const course = useSelector((state) => state.course);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const isGovIntro = course.data?.is_gov_intro;
  return isGovIntro && enrolledDetail.data?.week === 1;
};

export const useUser = () => {
  const authentication = useSelector((state) => state.authentication);
  const { user } = authentication;
  return user;
};

export const getUserId = () => {
  const userId = getCookie("user_id");
  return userId;
};

export const useIsDesktop = () => {
  const windowSize = useWindowDimensions();
  const isDesktop = windowSize.width > size.desktop;
  return isDesktop;
};

export const useIsTablet = () => {
  const windowSize = useWindowDimensions();
  const isTablet =
    size.mobile < windowSize.width && windowSize.width < size.desktop;
  return isTablet;
};

export const useIsRealDesktop = () => {
  const windowSize = useWindowDimensions();
  const isDesktop = windowSize.width > size.desktop;
  return isDesktop;
};

export const useIsB2G2BTestNeed = () => {
  const enrolled = useSelector((state) => state.enrolled);
  const hasTestSystem = enrolled.data?.courses.has_completion_test;
  return (
    hasTestSystem &&
    enrolled.data?.progress_rate >= 80 &&
    !enrolled.data?.has_passed_test
  );
};

const goHomework = (
  possibleToGoNext,
  enrolled,
  clickedDetailId,
  isLimitedGov
) => {
  if (!possibleToGoNext) {
    alert(
      "수강 기록 미비로 다음 강의로 넘어갈 수 없습니다.\n새로고침 후 다시 시도해주세요!"
    );
    return;
  }
  const {
    enrolled_id,
    round_id,
    course_id,
    rounds: { is_forever, is_seen_restricted },
    courses: { is_grammer },
  } = enrolled;
  if (isLimitedGov) {
    alert(`하루에 최대 ${GOV_DONE_DAILY_LIMIT + 1} 강까지 들을 수 있어요!`);
    history.push(`/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`);
    return;
  }
  history.push(`/enrolleds/${enrolled_id}/ehomeworks/${clickedDetailId}`);
};

const doDetail = async (
  enrolled,
  enrolledDetail,
  enrolledProgress,
  currentDetailId,
  possibleToGoNext,
  targetDetailId,
  isLimitedGov,
  isHomework = false,
  setIsForceUpdate,
  setVisibleModal,
  setBadge,
  setDelta,
  hasPointSystem,
  clickedDetailDone,
  checkSkipAvailable
) => {
  const {
    rounds: { is_gov_credit },
  } = enrolled;

  // 정부 크레딧 과목일 경우, 강의 시간의 50% 이상을 수강(체류)하지 않았을 경우 넘어가지 못함.
  if (is_gov_credit) {
    if (!checkSkipAvailable(enrolledDetail)) {
      setVisibleModal("nbSkip");
      return;
    }
  }

  const doneEnrolledDetail = await enrolledService.doEnrolledDetail(
    enrolled.enrolled_id,
    currentDetailId
  );
  const {
    rounds: { is_gov },
  } = enrolled;
  const { enrolled_id } = enrolled;
  const { new_badges, delta: _delta } = doneEnrolledDetail.context;
  setBadge(new_badges);
  setDelta(_delta);

  sendLog(
    "scc_lecture_completed",
    { ...enrolled, ...enrolledDetail, progress: enrolledProgress },
    "",
    true
  );
  if (is_gov) {
    const result = await govService.doGovEnrolledDetail(
      enrolled_id,
      currentDetailId
    );
    if (result.ok) {
      setIsForceUpdate(true);
      return isHomework
        ? goHomework(possibleToGoNext, enrolled, targetDetailId, isLimitedGov)
        : goLecture(
            possibleToGoNext,
            enrolled,
            targetDetailId,
            isLimitedGov,
            clickedDetailDone
          );
    } else {
      console.error("정부 수강 기록이 잘 남겨지지 않음");
    }
  }
  if (isHomework) {
    goHomework(possibleToGoNext, enrolled, targetDetailId, isLimitedGov);
    return;
  }
  if (!hasPointSystem) {
    goLecture(
      possibleToGoNext,
      enrolled,
      targetDetailId,
      isLimitedGov,
      clickedDetailDone
    );
    return;
  }
  if (new_badges && new_badges.length > 0) {
    return setVisibleModal("badge");
  }
  setVisibleModal("point");
  setTimeout(() => {
    /**
     * isForceUpdate 는 done 처리한뒤 강의 목록을 업데이트 해주기 위함
     * 실제 업데이트 로직은 useForceUpdate 에 존재.*/
    setIsForceUpdate(true);
    goLecture(
      possibleToGoNext,
      enrolled,
      targetDetailId,
      isLimitedGov,
      clickedDetailDone
    );
    setVisibleModal("");
  }, 1300);
};

const goLecture = (
  possibleToGoNext,
  enrolled,
  clickedDetailId,
  isLimitedGov,
  clickedDetailDone
) => {
  if (!possibleToGoNext) {
    alert(
      "수강 기록 미비로 다음 강의로 넘어갈 수 없습니다.\n새로고침 후 다시 시도해주세요!"
    );
    return;
  }
  const {
    enrolled_id,
    round_id,
    course_id,
    rounds: { is_forever, is_seen_restricted },
    courses: { is_grammer },
  } = enrolled;
  if (!clickedDetailDone && isLimitedGov) {
    alert(`하루에 최대 ${GOV_DONE_DAILY_LIMIT + 1} 강까지 들을 수 있어요!`);
    history.push(`/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`);
    return;
  }
  history.push(
    `/enrolleds/${enrolled_id}/edetails/${clickedDetailId}?course_id=${course_id}`
  );
};
/**
 * GovStatus 에 사용되는 state 들은 lecNextBtn 에서 이미 initialize 되어 있다는 것을 전제한다*/
const useCheckGovStatus = () => {
  const enrolledDoneCount = useSelector((state) => state.enrolledDoneCount);
  const isWrittenGovRecord = useSelector((state) => state.isWrittenGovRecord);
  // 정부 과목 검사하기 전엔 못넘어감
  // 정부 과목 레코드 관련 작업 (일반과목 포함) 이 잘 마무리되기 전까지는 못 넘어감
  /**TODO: OEC 작업으로 임시 주석처리. 절대 이대로 배포되면 안됨.*/
  return enrolledDoneCount.loaded;
  // isWrittenGovRecord.loaded &&
  // isWrittenGovRecord.data
};
/**
 * GovLimit 에 사용되는 state 들은 lecNextBtn 에서 이미 initialize 되어 있다는 것을 전제한다*/
const useCheckGovLimit = () => {
  const enrolled = useSelector((state) => state.enrolled);
  const enrolledDoneCount = useSelector((state) => state.enrolledDoneCount);
  const {
    rounds: { is_seen_restricted },
  } = enrolled.data;
  return is_seen_restricted && enrolledDoneCount.data >= GOV_DONE_DAILY_LIMIT;
};
/**
 * 함수 흐름
 * 1. 클릭된 enrolledDetail 이 done 인지 확인한다. true 라면 해당 강의로 이동한다.
 * 2. done false 라면
 *    2-1. 바로 지금 보는 강의의 바로 다음 강의인지 확인한다.
 *    (이전강의가 seen True 이고 done False 여야한다) 다음강의라면 doEnrollDetail 을 하면서 이동한다
 *    2-2. 바로 다음강의가 아니라면 '이전 강의의 수강을 먼저해달라'는 안내를 띄우며 종료한다.*/
export const useClickLecture = (
  clickedDetailDone,
  clickedWeekIndex,
  enrolledWeek,
  clickedDetailId,
  clickedLectureWeek
) => {
  const enrolled = useSelector((state) => state.enrolled);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const enrolledProgress = useSelector((state) => state.enrolledProgress);
  const possibleToGoNext = useCheckGovStatus();
  const isLimitedGov = useCheckGovLimit();
  const getPoint = useGetPoint();
  const round = useSelector((state) => state.round);
  const dispatch = useDispatch();
  const toastAlert = useToastMessage();
  const isAllowed = useGetIsAllowed(
    enrolled.data?.enrolled_id,
    clickedLectureWeek,
    clickedWeekIndex,
    enrolledWeek
  );
  const checkSkipAvailable = useNbSkipModalData().checkSkipAvailable;
  const hasPointSystem = round.data?.has_point_system !== false;
  const [, setIsForceUpdate] = useAtom(forceUpdateAtom);
  const [, setVisibleModal] = useAtom(modalTypeAtom);
  const [, setBadge] = useAtom(badgeAtom);
  const [, setDelta] = useAtom(deltaAtom);
  const findPreviousDetail = useFindPreviousDetail(enrolled.data?.enrolled_id);
  return async () => {
    const previousDetail = findPreviousDetail(clickedDetailId);
    if (!isAllowed) {
      alert("앗, 이전 강의 수강을 먼저 마무리 해주세요.");
      window.location.reload();
      return;
    }
    // 0. 맨 처음 강의는 바로 이동
    if (!clickedDetailDone && clickedWeekIndex === 0) {
      if (previousDetail && previousDetail.seen && !previousDetail.done) {
        const targetDetailId = previousDetail.enrolled_detail_id;
        getPoint(dispatch);
        await doDetail(
          enrolled.data,
          enrolledDetail.data,
          enrolledProgress.data,
          targetDetailId,
          possibleToGoNext,
          clickedDetailId,
          isLimitedGov,
          false,
          setIsForceUpdate,
          setVisibleModal,
          setBadge,
          setDelta,
          hasPointSystem,
          clickedDetailDone,
          checkSkipAvailable
        );
        return;
      }
      // 0-1. 이전 강의가 없다는 것은 가장 첫 강의라는 것이므로 바로 이동
      goLecture(
        possibleToGoNext,
        enrolled.data,
        clickedDetailId,
        isLimitedGov,
        clickedDetailDone
      );
      return;
    }
    // 1. 클릭된 enrolledDetail 이 done 인지 확인한다. true 라면 해당 강의로 이동한다.
    if (
      clickedDetailDone ||
      (clickedWeekIndex > 0 &&
        previousDetail &&
        previousDetail.seen &&
        previousDetail.done)
    ) {
      goLecture(
        possibleToGoNext,
        enrolled.data,
        clickedDetailId,
        isLimitedGov,
        clickedDetailDone
      );
      return;
    }
    if (previousDetail && previousDetail.seen && !previousDetail.done) {
      const targetDetailId = previousDetail.enrolled_detail_id;
      getPoint(dispatch);
      await doDetail(
        enrolled.data,
        enrolledDetail.data,
        enrolledProgress.data,
        targetDetailId,
        possibleToGoNext,
        clickedDetailId,
        isLimitedGov,
        false,
        setIsForceUpdate,
        setVisibleModal,
        setBadge,
        setDelta,
        hasPointSystem,
        clickedDetailDone,
        checkSkipAvailable
      );
      return;
    }
    // goLecture(
    //   possibleToGoNext,
    //   enrolled.data,
    //   clickedDetailId,
    //   isLimitedGov,
    //   clickedDetailDone
    // );
  };
};

export const useClickHomework = (
  clickedDetailDone,
  clickedWeekIndex,
  enrolledWeek,
  clickedDetailId
) => {
  const enrolled = useSelector((state) => state.enrolled);
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const enrolledProgress = useSelector((state) => state.enrolledProgress);
  const possibleToGoNext = useCheckGovStatus();
  const isLimitedGov = useCheckGovLimit();
  const round = useSelector((state) => state.round);
  const checkSkipAvailable = useNbSkipModalData().checkSkipAvailable;
  const [, setVisibleModal] = useAtom(modalTypeAtom);
  const hasPointSystem = round.data?.has_point_system !== false;

  /**
   * goHomework 가 실행되는건 무조건 목차에서 숙제를 클릭 했을 때 이다.
   * 1. 클릭된 homework 기준으로 이전 enrolledDetail 의 done을 확인한다. done true 라면 숙제 제출 페이지로 이동한다.
   * 2. done false 라면 이전 강의 수강을 먼저 마무리 해달라 는 안내를 띄우며 종료한다. */
  return async () => {
    if (enrolledWeek[clickedWeekIndex - 1].seen) {
      if (!enrolledWeek[clickedWeekIndex - 1].done) {
        await doDetail(
          enrolled.data,
          enrolledDetail.data,
          enrolledProgress.data,
          enrolledWeek[clickedWeekIndex - 1].detailId,
          possibleToGoNext,
          clickedDetailId,
          isLimitedGov,
          true,
          () => {},
          setVisibleModal,
          () => {},
          () => {},
          hasPointSystem,
          clickedDetailDone,
          checkSkipAvailable
        );
      } else {
        const { enrolled_id, round_id } = enrolled.data;
        if (isLimitedGov) {
          alert(
            `하루에 최대 ${GOV_DONE_DAILY_LIMIT + 1} 강까지 들을 수 있어요!`
          );
          history.push(`/enrolleds/${enrolled_id}/rounds/${round_id}/roadmap`);
          return;
        }
        history.push(
          `/enrolleds/${enrolled.data.enrolled_id}/ehomeworks/${clickedDetailId}`
        );
      }
    } else {
      // toastAlert("앗, 이전 강의 수강을 먼저 마무리 해주세요.");
      alert("앗, 이전 강의 수강을 먼저 마무리 해주세요.");
      window.location.reload();
    }
  };
};

/**
 * enrolledDetails 와 roundWeeks 를 조합하여 강의 목차에 필요한
 * enrolledWeeks 를 리턴해주는 함수. */
export const useGetModifiedEnrolledDetails = (isInitialized) => {
  const enrolledDetail = useSelector((state) => state.enrolledDetail);
  const course = useSelector((state) => state.course);
  const roundWeeks = useSelector((state) => state.roundWeeks);
  const enrolledDetails = useSelector((state) => state.enrolledDetails);
  const [modifiedWeeks, setModifiedWeeks] = useState([]);
  const findTargetEnrolledDetailId = (enrolledDetails, enrolledDetailId) => {
    return (
      enrolledDetails.filter(
        (enrolledDetail) =>
          enrolledDetail.enrolled_detail_id === enrolledDetailId
      ).length !== 0
    );
  };
  const findIndexById = (enrolledId, enrolledArray) => {
    return enrolledArray.findIndex(
      (enrolledObj) => enrolledObj.detailId === enrolledId
    );
  };
  useEffect(() => {
    if (isInitialized && enrolledDetails.data) {
      let enrolledWeeks = [];
      enrolledDetails.data.weeks.map((week, index) => {
        let weekDetailsArray = [];
        week.enrolled_details.map((enrolled_detail, index) => {
          let tempEnrolledObj = {
            lectureOrder: `${enrolled_detail.week}-${index + 1}`,
            lectureTitle: enrolled_detail.title,
            lectureTime: enrolled_detail.playtime,
            lectureWeek: enrolled_detail.week,
            done: enrolled_detail.done,
            seen: enrolled_detail.seen,
            detailId: enrolled_detail.enrolled_detail_id,
          };
          weekDetailsArray.push(tempEnrolledObj);
        });
        if (week.homework) {
          let tempHomeworkObject = {
            lectureOrder: "숙제",
            lectureTitle: week.homework.title,
            lectureTime: "",
            lectureWeek: week.week,
            done: week.homework.done,
            seen: false,
            detailId: week.homework._id,
          };
          weekDetailsArray.push(tempHomeworkObject);
        }

        const isCurrent = findTargetEnrolledDetailId(
          week.enrolled_details,
          enrolledDetail.data.enrolled_detail_id
        );
        let tempEnrolledWeekObject = {
          courseTitle: course.data.title,
          weekOrder: `${week.week}주차`,
          lectureLength: `총 ${week.enrolled_details.length}강`,
          sumPlayTime: roundWeeks.data.weeks[index]?.sum_of_playtime,
          weekDetails: weekDetailsArray,
          nowPlayingIndex: isCurrent
            ? findIndexById(
                enrolledDetail.data.enrolled_detail_id,
                weekDetailsArray
              )
            : -1,
        };
        enrolledWeeks.push(tempEnrolledWeekObject);
      });
      setModifiedWeeks(enrolledWeeks);
    } else {
      return;
    }
  }, [isInitialized, roundWeeks.loaded]);
  return modifiedWeeks;
};

export const useInitializeExpired = (enrolled_id) => {
  const expired = useSelector((state) => state.expired);
  const getExpired = useGetExpired(enrolled_id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!expired.loaded) {
      getExpired(dispatch);
    }
  }, [expired.loaded]);
  return expired.loaded;
};

export const useGetIsAllowed = (enrolled_id, week, idx, details) => {
  // 최상위에서 expired 가 loaded 되었음을 전제
  const expired = useSelector((state) => state.expired);
  const isDone =
    week <= expired.data?.achv.week && (idx === 0 || details[idx - 1].done);
  const isNext =
    week === expired.data?.achv.week && idx >= 1 && details[idx - 1].seen;
  const isPossibleGoNextWeek =
    week <= expired.data?.achv.week + 1 &&
    idx === 0 &&
    expired.data?.achv.is_possible_go_next_week;
  return isDone || isNext || isPossibleGoNextWeek;
};

export const useGoRoadmapPage = (enrolled_id) => {
  const enrolled = useSelector((state) => state.enrolled);
  const getEnrolled = useGetEnrolled(enrolled_id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!enrolled.loaded) {
      getEnrolled(dispatch);
    }
  }, [enrolled.loaded]);
  return () => {
    history.push(
      `/enrolleds/${enrolled.data?.enrolled_id}/rounds/${enrolled.data?.round_id}/roadmap`
    );
  };
};

export const useForceUpdate = (enrolled_id) => {
  const [isForceUpdate, setIsForceUpdate] = useAtom(forceUpdateAtom);
  const getEnrolledDetails = useGetEnrolledDetails(enrolled_id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isForceUpdate) {
      getEnrolledDetails(dispatch);
      setIsForceUpdate(false);
    }
  }, [isForceUpdate]);
};

export const useHandlePlayerCss = (bufferHandler) => {
  const [isOpenList] = useAtom(isLectureListOpen);
  useEffect(() => {
    if (isOpenList) {
      bufferHandler(isOpenList);
      return;
    } else {
      setTimeout(() => {
        bufferHandler(isOpenList);
      }, 500);
    }
  }, [isOpenList]);
};

export const useInitializeMaterial = (
  enrolled_id,
  enrolled_detail_id,
  faqHandler
) => {
  const material = useSelector((state) => state.material);
  const getMaterial = useGetMaterial(enrolled_id, enrolled_detail_id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!material.loaded && enrolled_detail_id && enrolled_id) {
      getMaterial(dispatch);
    }
    if (material.loaded && material.data.faq_url !== "") {
      faqHandler(true);
    } else faqHandler(false);
  }, [material.loaded]);
  return material;
};

export const useInitializeRound = (round_id) => {
  const round = useSelector((state) => state.round);
  const getRound = useGetRound(round_id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!round.loaded) {
      getRound(dispatch);
    }
  }, [round.loaded]);
  return round;
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useInitializeCodeSnippets = (enrolled_id, enrolled_detail_id) => {
  const codeSnippets = useSelector((state) => state.codeSnippets);
  const getCodeSnippets = useGetCodeSnippets(enrolled_id, enrolled_detail_id);
  const prevDetail = usePrevious(enrolled_detail_id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!codeSnippets.loaded || prevDetail !== enrolled_detail_id) {
      getCodeSnippets(dispatch);
    }
  }, [codeSnippets.loaded, enrolled_detail_id]);
  return codeSnippets;
};

export const useCodeExamples = (enrolled_detail_id) => {
  return useQuery(
    ["codeExamples", enrolled_detail_id],
    () => getCodeExamples(enrolled_detail_id),
    {
      enabled: !!enrolled_detail_id,
      refetchOnWindowFocus: false,
    }
  );
};

export const useModifyCodeSnippets = (codeSnippets) => {
  if (!codeSnippets) return;
  let codeArray = [];
  codeSnippets.map((snippet) => {
    let snippetObj = {
      content: snippet.code,
      title: snippet.title,
      _id: snippet._id,
    };
    codeArray.push(snippetObj);
  });
  return codeArray;
};

export const useModifyMaterials = (weeks) => {
  const courseId = useCourseId();
  const syllabusUnit = useSyllabusUnit(courseId);
  if (!weeks) return;
  let materialArray = [];
  weeks.map((week, index) => {
    if (!week.material) return;
    let materialObj = {
      content: week.material.materials.link,
      title: `${week.week}${syllabusUnit} 강의자료`,
      _id: week.material.material_id,
    };
    materialArray.push(materialObj);
  });
  return materialArray;
};

export const useIsCardQuizLecture = (course, detail_id, enrolledWeeks) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!enrolledWeeks || enrolledWeeks.length === 0) {
      setIsLoaded(false);
      return;
    }

    const aWeekDetails = enrolledWeeks[enrolledWeeks.length - 1].weekDetails;
    const lastDetail =
      aWeekDetails[aWeekDetails.length - 1].lectureOrder === "숙제"
        ? aWeekDetails[aWeekDetails.length - 2]
        : aWeekDetails[aWeekDetails.length - 1];

    const isQuizBannerLecture =
      FreeCourses.includes(course.data?.course_id) &&
      lastDetail.detailId === detail_id;

    setIsLoaded(isQuizBannerLecture);
  }, [course, detail_id, enrolledWeeks]);

  return isLoaded;
};
